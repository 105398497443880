
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*  🤖  Generated by generator/codegen.schema-info.ts
*  📅  Generated on 11/15/2024 at 11:49:44 PM
*  ⚠️  Do not modify this file directly. 
*     run "yarn workspace @attentive/mock-data generate" to update
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

export const connectionFields = [
  {
    "objectTypeName": "Account",
    "fieldName": "recentCompanies"
  },
  {
    "objectTypeName": "CampaignMessageGroup",
    "fieldName": "assignedTags"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "aIGeneratedTexts"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "accounts"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "affiliateUsers"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "blogPosts"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "brandAssets"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "builtApplications"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "clientConversations"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "contentSettings"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "conversations"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "couponSets"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativeExperiments"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativePresets"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativeScheduleWorkflows"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativeSchedules"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativeTemplates"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creatives"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativesListWithABTests"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "creativesV2"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "customEventDefinitions"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "customEventKeys"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "customEvents"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "dataset"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "deleteRequests"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "dimensionValues"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "etlConfigs"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "installedApplications"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "journeys"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "legacyReports"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "legacyScheduledReports"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "legacySegments"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "messageFeedback"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "messageTemplates"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "metricsTable"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "nextBestActions"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "notifications"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "paginatedProducts"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "polls"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "pollsV2"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "privacyRequests"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "productCategories"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "productCollections"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "productPropertyOptions"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "productSegments"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "productTags"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "reports"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "scheduleEligibleReports"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "scheduledReports"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "segments"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "sharedMessageTemplates"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "subscriberUploads"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "tags"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "userPropertyDefinitions"
  },
  {
    "objectTypeName": "Company",
    "fieldName": "vendorCompanyCards"
  },
  {
    "objectTypeName": "ComposeCampaign",
    "fieldName": "campaignMessageGroups"
  },
  {
    "objectTypeName": "Concierge",
    "fieldName": "specializations"
  },
  {
    "objectTypeName": "Concierge",
    "fieldName": "unhandledUsers"
  },
  {
    "objectTypeName": "Country",
    "fieldName": "postalCodes"
  },
  {
    "objectTypeName": "CouponSet",
    "fieldName": "couponUploads"
  },
  {
    "objectTypeName": "Creative",
    "fieldName": "creativeExperimentGroups"
  },
  {
    "objectTypeName": "Creative",
    "fieldName": "creativeScheduleMembers"
  },
  {
    "objectTypeName": "Creative",
    "fieldName": "creativeScheduleWorkflows"
  },
  {
    "objectTypeName": "CreativeExperiment",
    "fieldName": "creativeExperimentGroups"
  },
  {
    "objectTypeName": "CreativeExperimentGroup",
    "fieldName": "creatives"
  },
  {
    "objectTypeName": "CreativeFormat",
    "fieldName": "creativePresets"
  },
  {
    "objectTypeName": "CreativeSchedule",
    "fieldName": "creativeScheduleMembers"
  },
  {
    "objectTypeName": "CreativeWorkflowSchedule",
    "fieldName": "creativeWorkflowScheduleMembers"
  },
  {
    "objectTypeName": "CustomEventDefinition",
    "fieldName": "keys"
  },
  {
    "objectTypeName": "EmailIPAddress",
    "fieldName": "assignedEmailIpPools"
  },
  {
    "objectTypeName": "EmailIPPool",
    "fieldName": "assignedIpAddresses"
  },
  {
    "objectTypeName": "EmailIPPool",
    "fieldName": "companies"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "destinations"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "jobHistory"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "jobHistoryV2"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "jobHistoryV3"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "jobReports"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "jobs"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "nodes"
  },
  {
    "objectTypeName": "EventDestinations",
    "fieldName": "nodesByTag"
  },
  {
    "objectTypeName": "FeatureFlag",
    "fieldName": "targetingData"
  },
  {
    "objectTypeName": "Folder",
    "fieldName": "childAssets"
  },
  {
    "objectTypeName": "PrebuiltContent",
    "fieldName": "orchestrations"
  },
  {
    "objectTypeName": "Product",
    "fieldName": "variants"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "accounts"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "accountsByEmail"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "adminNotificationConfigurations"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "agentTemplateRequests"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "applicationWebhookEvents"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "automationPrograms"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "companies"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "composeCampaigns"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "conciergeCompanyTemplates"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "contentSettings"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "creativeFormats"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "customCompanyKeywords"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "deleteRequests"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "dismissibleModals"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "domainSuppressions"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "emailAccounts"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "emailIpAddresses"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "emailIpPools"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "enabledFlags"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "escalatedConciergeConversations"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "eventDestinationJobs"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "featureFlags"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "globalMessageTemplates"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "marketplaceApplications"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "messageFeedback"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "messages"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "notificationBanners"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "notifications"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "phoneNumbers"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "postalCodes"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "programOrchestrations"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "targetingData"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "users"
  },
  {
    "objectTypeName": "Query",
    "fieldName": "zendeskHelpCenterArticles"
  },
  {
    "objectTypeName": "User",
    "fieldName": "contentSettings"
  },
  {
    "objectTypeName": "User",
    "fieldName": "coupons"
  },
  {
    "objectTypeName": "User",
    "fieldName": "messages"
  },
  {
    "objectTypeName": "User",
    "fieldName": "userPropertiesV2"
  }
];

export const validEnumValues: Record<string, string[]> = {
  "AIBrandVoiceAllCapsUsage": [
    "ALL_CAPS_USAGE_ADAPTIVE",
    "ALL_CAPS_USAGE_FREQUENTLY",
    "ALL_CAPS_USAGE_NEVER",
    "ALL_CAPS_USAGE_SPARINGLY"
  ],
  "AIBrandVoiceEmojiFrequency": [
    "EMOJI_FREQUENCY_ADAPTIVE",
    "EMOJI_FREQUENCY_FREQUENTLY",
    "EMOJI_FREQUENCY_NEVER",
    "EMOJI_FREQUENCY_SPARINGLY"
  ],
  "AIBrandVoiceProductTitleFormatting": [
    "PRODUCT_TITLE_FORMATTING_LOWER_CASE",
    "PRODUCT_TITLE_FORMATTING_TITLE_CASE",
    "PRODUCT_TITLE_FORMATTING_UPPER_CASE"
  ],
  "AIBrandVoiceUrgency": [
    "URGENCY_ADAPTIVE",
    "URGENCY_FREQUENTLY",
    "URGENCY_NEVER",
    "URGENCY_SPARINGLY"
  ],
  "AIGeneratedTextOrigin": [
    "GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS",
    "GENERATED_TEXT_ORIGIN_AUTOMATED_JOURNEYS_CHAIN",
    "GENERATED_TEXT_ORIGIN_AUTOMATED_WELCOME_JOURNEY",
    "GENERATED_TEXT_ORIGIN_BRAND_VOICE_SUMMARY",
    "GENERATED_TEXT_ORIGIN_CAMPAIGN_SUGGESTIONS_HOMEPAGE",
    "GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_EMAIL_SUBJECT_LINE",
    "GENERATED_TEXT_ORIGIN_COPY_ASSISTANT_SMS"
  ],
  "AIImageGenerationType": [
    "AI_IMAGE_GENERATION_TYPE_SCENE_CONSTRUCTION"
  ],
  "Aggregator": [
    "AGGREGATOR_OPEN_MARKET",
    "AGGREGATOR_SAP",
    "AGGREGATOR_SYNIVERSE",
    "AGGREGATOR_UNSET"
  ],
  "AiProEligibilityStatus": [
    "AI_PRO_ELIGIBILITY_STATUS_ELIGIBLE",
    "AI_PRO_ELIGIBILITY_STATUS_ENROLLED",
    "AI_PRO_ELIGIBILITY_STATUS_INELIGIBLE",
    "AI_PRO_ELIGIBILITY_STATUS_PENDING"
  ],
  "ApplicableCustomers": [
    "ALL_CUSTOMERS",
    "SELECT_CUSTOMERS",
    "SELECT_SEGMENTS",
    "UNKNOWN_CUSTOMER_TYPE"
  ],
  "ApplicableProductType": [
    "ALL_ITEMS",
    "SELECT_CATEGORIES",
    "SELECT_PRODUCTS",
    "UNKNOWN_PRODUCT_TYPE"
  ],
  "ApplicationCategoryName": [
    "ANALYTICS",
    "AUTOMATION",
    "CUSTOMER_DATA_PLATFORM",
    "CUSTOMER_EXPERIENCE",
    "ECOMMERCE",
    "EMAIL_SERVICE_PROVIDER",
    "LOYALTY",
    "OTHER",
    "REVIEWS_UGC",
    "SHIPPING_RETURNS"
  ],
  "ApplicationWebhookType": [
    "SUBSCRIPTION",
    "UNIVERSAL"
  ],
  "ApprovalStatus": [
    "APPROVAL_STATUS_APPROVAL_REQUESTED",
    "APPROVAL_STATUS_APPROVED",
    "APPROVAL_STATUS_NEEDS_APPROVAL",
    "APPROVAL_STATUS_REJECTED"
  ],
  "AssetsDirection": [
    "ASSETS_DIRECTION_ASCENDING",
    "ASSETS_DIRECTION_DESCENDING"
  ],
  "AssetsOrderBy": [
    "ASSETS_ORDER_BY_DATE_ADDED",
    "ASSETS_ORDER_BY_DATE_MODIFIED",
    "ASSETS_ORDER_BY_DIMENSIONS",
    "ASSETS_ORDER_BY_DISPLAY_NAME",
    "ASSETS_ORDER_BY_SIZE",
    "ASSETS_ORDER_BY_TYPE"
  ],
  "AssignmentLocation": [
    "FIRST_NAME",
    "LAST_NAME"
  ],
  "AttachmentType": [
    "ATTACHMENT_TYPE_EVENT_LOOKUP",
    "ATTACHMENT_TYPE_OFFER",
    "ATTACHMENT_TYPE_RECOMMENDATION",
    "ATTACHMENT_TYPE_USER_PROPERTY"
  ],
  "AttentiveAwsRegion": [
    "ATTENTIVE_AWS_REGION_US_EAST_1",
    "ATTENTIVE_AWS_REGION_US_EAST_2"
  ],
  "AttentiveFeatureType": [
    "JOURNEYS",
    "PRIVACY",
    "SEGMENTS",
    "SUBSCRIBERS"
  ],
  "AttributeScope": [
    "ATTRIBUTE_SCOPE_COMPANY",
    "ATTRIBUTE_SCOPE_GLOBAL"
  ],
  "AttributionWindowState": [
    "ATTRIBUTION_WINDOW_STATE_NULL",
    "ATTRIBUTION_WINDOW_STATE_SET"
  ],
  "AuditSubscriberStatusV2": [
    "AUDIT_SUBSCRIBER_STATUS_AUDIT_REPORT_RUNNING",
    "AUDIT_SUBSCRIBER_STATUS_FAILURE",
    "AUDIT_SUBSCRIBER_STATUS_PHONE_INVALID",
    "AUDIT_SUBSCRIBER_STATUS_SUCCESS"
  ],
  "AutoCampaignFilter": [
    "AUTO_CAMPAIGN_FILTER_ALL",
    "AUTO_CAMPAIGN_FILTER_AUTO",
    "AUTO_CAMPAIGN_FILTER_MANUAL"
  ],
  "AutoJourneyStatus": [
    "AUTO_JOURNEY_STATUS_ACTIVE",
    "AUTO_JOURNEY_STATUS_DELETED",
    "AUTO_JOURNEY_STATUS_PAUSED"
  ],
  "AutoJourneyTriggerType": [
    "AUTO_JOURNEY_TRIGGER_TYPE_CART_ABANDONMENT"
  ],
  "AutoJourneyType": [
    "CART_ABANDONMENT",
    "UNIFIED",
    "WELCOME"
  ],
  "AutomatedMessageType": [
    "AUTOMATED_MESSAGE_TYPE_ALREADY_SUBSCRIBED",
    "AUTOMATED_MESSAGE_TYPE_AUTO",
    "AUTOMATED_MESSAGE_TYPE_DOUBLE_OPT_IN",
    "AUTOMATED_MESSAGE_TYPE_FUZZY_OPT_OUT",
    "AUTOMATED_MESSAGE_TYPE_HELP",
    "AUTOMATED_MESSAGE_TYPE_LEGAL",
    "AUTOMATED_MESSAGE_TYPE_START",
    "AUTOMATED_MESSAGE_TYPE_STOP",
    "AUTOMATED_MESSAGE_TYPE_UNSUBSCRIBE"
  ],
  "BackfillType": [
    "SCHEDULED_REPORT",
    "SFTP"
  ],
  "BlockCompanyInfoType": [
    "BLOCK_COMPANY_INFO_TYPE_ADDITIONAL",
    "BLOCK_COMPANY_INFO_TYPE_RETURNS",
    "BLOCK_COMPANY_INFO_TYPE_SHIPPING"
  ],
  "ButtonAction": [
    "BUTTON_ACTION_CLOSE",
    "BUTTON_ACTION_LINK"
  ],
  "CadenceComparator": [
    "CADENCE_COMPARATOR_LATEST"
  ],
  "CampaignAction": [
    "CAMPAIGN_ACTION_CAMPAIGN_RATE_LIMIT_UPDATED",
    "CAMPAIGN_ACTION_CANCELLED",
    "CAMPAIGN_ACTION_CREATED",
    "CAMPAIGN_ACTION_DELETED",
    "CAMPAIGN_ACTION_DOWNGRADED",
    "CAMPAIGN_ACTION_DRAFTED",
    "CAMPAIGN_ACTION_PAUSED",
    "CAMPAIGN_ACTION_RESCHEDULED",
    "CAMPAIGN_ACTION_RESUMED",
    "CAMPAIGN_ACTION_SEND_FINISHED",
    "CAMPAIGN_ACTION_SEND_STARTED",
    "CAMPAIGN_ACTION_TIMEZONE_SEND_FINISHED",
    "CAMPAIGN_ACTION_TIMEZONE_SEND_STARTED",
    "CAMPAIGN_ACTION_UPDATED"
  ],
  "CampaignCompositionValidationErrorCode": [
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_COUPON_REFILL_REQUIRED",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_FROM_ADDRESS_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_REPLY_TO_ADDRESS_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMAIL_MESSAGE_SUBJECT_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_CAMPAIGN",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_EMPTY_EXPERIMENT_METADATA",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_LIMIT_EXCEEDED",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_GROUP_ORDER_ERROR",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_INVALID_SEGMENT",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_MESSAGE_BODY_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_NAME_LENGTH_EXCEEDED",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SEGMENT_INCLUDED_AND_EXCLUDED",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_EMPTY",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_START_TIME_INVALID",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STE_MESSAGE_CONTENT_OUT_OF_SYNC",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_STO_DATE_CONFLICT",
    "CAMPAIGN_COMPOSITION_VALIDATION_ERROR_CODE_SUBSCRIBER_LOCAL_TZ_SEND_NOT_ALLOWED"
  ],
  "CampaignExperimentSubtype": [
    "CAMPAIGN_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT",
    "CAMPAIGN_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT"
  ],
  "CampaignMessageGroupExperimentSubtype": [
    "CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_CONTENT_BODY_EXPERIMENT",
    "CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_SUBTYPE_SUBJECT_LINE_EXPERIMENT"
  ],
  "CampaignMessageGroupExperimentType": [
    "CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_EXPERIMENT",
    "CAMPAIGN_MESSAGE_GROUP_EXPERIMENT_TYPE_SEND_TIME_EXPERIMENT"
  ],
  "CampaignMessageGroupStatus": [
    "CAMPAIGN_MESSAGE_GROUP_STATUS_ATTENTIVE_REVIEW",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_CANCELLED",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_DRAFT",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_NEEDS_COUPONS",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_PAUSED",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_RESCHEDULED",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_SCHEDULED_DRAFT",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_SENDING",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_SEND_ERROR",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_SENT",
    "CAMPAIGN_MESSAGE_GROUP_STATUS_UNSAVED_DRAFT"
  ],
  "CampaignMessageGroupType": [
    "CAMPAIGN_MESSAGE_GROUP_TYPE_EXPERIMENT",
    "CAMPAIGN_MESSAGE_GROUP_TYPE_ONE_TIME",
    "CAMPAIGN_MESSAGE_GROUP_TYPE_SEND_TIME_EXPERIMENT"
  ],
  "CampaignScheduleType": [
    "CAMPAIGN_SCHEDULE_TYPE_INSTANT",
    "CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_LOCAL_TIME",
    "CAMPAIGN_SCHEDULE_TYPE_SUBSCRIBER_OPTIMIZED_SEND_TIME"
  ],
  "CampaignStatus": [
    "CAMPAIGN_STATUS_ATTENTIVE_REVIEW",
    "CAMPAIGN_STATUS_CANCELLED",
    "CAMPAIGN_STATUS_DRAFT",
    "CAMPAIGN_STATUS_NEEDS_COUPONS",
    "CAMPAIGN_STATUS_PAUSED",
    "CAMPAIGN_STATUS_RESCHEDULED",
    "CAMPAIGN_STATUS_SCHEDULED",
    "CAMPAIGN_STATUS_SCHEDULED_DRAFT",
    "CAMPAIGN_STATUS_SENDING",
    "CAMPAIGN_STATUS_SEND_ERROR",
    "CAMPAIGN_STATUS_SENT",
    "CAMPAIGN_STATUS_UNSAVED_DRAFT"
  ],
  "CampaignType": [
    "CAMPAIGN_TYPE_EXPERIMENT",
    "CAMPAIGN_TYPE_ONE_TIME",
    "CAMPAIGN_TYPE_SEND_TIME_EXPERIMENT"
  ],
  "CampaignWinningVariantCriteria": [
    "CAMPAIGN_WINNING_VARIANT_CRITERIA_CLICK_THROUGH_RATE",
    "CAMPAIGN_WINNING_VARIANT_CRITERIA_CONVERSION_RATE",
    "CAMPAIGN_WINNING_VARIANT_CRITERIA_OPEN_RATE",
    "CAMPAIGN_WINNING_VARIANT_CRITERIA_OPT_OUT_RATE",
    "CAMPAIGN_WINNING_VARIANT_CRITERIA_TOTAL_REVENUE"
  ],
  "Carrier": [
    "CARRIER_APPALACHIAN",
    "CARRIER_ATT",
    "CARRIER_BELL",
    "CARRIER_CLEARSKY",
    "CARRIER_GOOGLE_VOICE",
    "CARRIER_INTEROP",
    "CARRIER_MISC",
    "CARRIER_ROGERS",
    "CARRIER_TELUS",
    "CARRIER_TMOBILE",
    "CARRIER_US_CELLULAR",
    "CARRIER_VERIZON"
  ],
  "Channel": [
    "CHANNEL_EMAIL",
    "CHANNEL_TEXT"
  ],
  "ChannelOptions": [
    "CHANNEL_EMAIL",
    "CHANNEL_SMS"
  ],
  "CheckpointStatus": [
    "STATUS_COMPLETED",
    "STATUS_FAILED",
    "STATUS_IN_PROGRESS"
  ],
  "CheckpointType": [
    "TYPE_CHECKPOINT",
    "TYPE_SAVEPOINT"
  ],
  "ClientConversationFilter": [
    "CLIENT_CONVERSATION_FILTER_ALL",
    "CLIENT_CONVERSATION_FILTER_CONCIERGE",
    "CLIENT_CONVERSATION_FILTER_CONCIERGE_CONVERTED",
    "CLIENT_CONVERSATION_FILTER_CONCIERGE_ESCALATED",
    "CLIENT_CONVERSATION_FILTER_MULTIPLE_CONCIERGE_CONVERSATION",
    "CLIENT_CONVERSATION_FILTER_UNREAD"
  ],
  "ClientConversationSort": [
    "CLIENT_CONVERSATION_SORT_LATEST",
    "CLIENT_CONVERSATION_SORT_OLDEST"
  ],
  "Cluster": [
    "CLUSTER_BFCM",
    "CLUSTER_CORE",
    "CLUSTER_FEEDER",
    "CLUSTER_LOADTEST",
    "CLUSTER_NORMA",
    "CLUSTER_PANDORA",
    "CLUSTER_SANDBOX",
    "CLUSTER_SOURCE",
    "CLUSTER_VIRGO"
  ],
  "CommonCreativeErrors": [
    "COMMON_CREATIVE_ERRORS_NAME_COLLISION"
  ],
  "CommonCreativeScheduleMutationErrors": [
    "COMMON_CREATIVE_SCHEDULE_ERRORS_OVERLAPPING_SCHEDULES"
  ],
  "CompanyLinkParameterMessageChannel": [
    "COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_EMAIL",
    "COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_GLOBAL",
    "COMPANY_LINK_PARAMETERS_MESSAGE_CHANNEL_TEXT"
  ],
  "CompanyLinkParameterMessageType": [
    "COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_CAMPAIGN",
    "COMPANY_LINK_PARAMETERS_MESSAGE_TYPE_JOURNEY"
  ],
  "CompanyRegionFatigueRuleType": [
    "COMPANY_REGION_FATIGUE_RULE_TYPE_EMAIL",
    "COMPANY_REGION_FATIGUE_RULE_TYPE_MESSAGE"
  ],
  "CompanyType": [
    "COMPANY_TYPE_ATTENTIVE",
    "COMPANY_TYPE_CLIENT",
    "COMPANY_TYPE_NON_STANDARD",
    "COMPANY_TYPE_TEST"
  ],
  "CompanyVendorIntegrationStatus": [
    "CONNECTED",
    "DISABLED",
    "ENABLED"
  ],
  "ComparisonDateFilterPeriodType": [
    "COMPARISON_DATE_FILTER_CUSTOM_PERIOD",
    "COMPARISON_DATE_FILTER_PREVIOUS_PERIOD",
    "COMPARISON_DATE_FILTER_PREVIOUS_YEAR"
  ],
  "ComposeCampaignStatus": [
    "COMPOSE_CAMPAIGN_STATUS_DRAFT",
    "COMPOSE_CAMPAIGN_STATUS_SCHEDULED",
    "COMPOSE_CAMPAIGN_STATUS_SENDING_STARTED",
    "COMPOSE_CAMPAIGN_STATUS_SENT"
  ],
  "ComposeCampaignType": [
    "COMPOSE_CAMPAIGN_TYPE_MULTI_MESSAGE",
    "COMPOSE_CAMPAIGN_TYPE_SINGLE_MESSAGE"
  ],
  "ComposeMessageChannel": [
    "COMPOSE_MESSAGE_CHANNEL_EMAIL",
    "COMPOSE_MESSAGE_CHANNEL_TEXT"
  ],
  "ComposeMessageStatus": [
    "COMPOSE_MESSAGE_STATUS_ACTIVE",
    "COMPOSE_MESSAGE_STATUS_DRAFT"
  ],
  "ComposeMessageTagType": [
    "COMPOSE_MESSAGE_TAG_INTERNAL",
    "COMPOSE_MESSAGE_TAG_PUBLIC"
  ],
  "ComposeMessageType": [
    "COMPOSE_MESSAGE_TYPE_AI_JOURNEY",
    "COMPOSE_MESSAGE_TYPE_CAMPAIGN",
    "COMPOSE_MESSAGE_TYPE_CONCIERGE",
    "COMPOSE_MESSAGE_TYPE_CONVERSATION",
    "COMPOSE_MESSAGE_TYPE_JOURNEY",
    "COMPOSE_MESSAGE_TYPE_PAYMENT",
    "COMPOSE_MESSAGE_TYPE_TRANSACTIONAL"
  ],
  "ConciergeCompanyPromotionType": [
    "COUPON",
    "EVENT"
  ],
  "ConciergeLinkType": [
    "CONCIERGE_LINK_TYPE_AGENT_CLICK_TO_BUY_CHECKOUT",
    "CONCIERGE_LINK_TYPE_ML_PRODUCT_RECOMMENDATION"
  ],
  "ConciergeSelfServeStatus": [
    "CONCIERGE_SELF_SERVE_STATUS_AGENT_PREFERENCES",
    "CONCIERGE_SELF_SERVE_STATUS_COMPLETED",
    "CONCIERGE_SELF_SERVE_STATUS_CUSTOMER_SUPPORT",
    "CONCIERGE_SELF_SERVE_STATUS_SHIPPING",
    "CONCIERGE_SELF_SERVE_STATUS_SIGN_UP"
  ],
  "ConciergeSpecializationsSortColumn": [
    "CONCIERGE_SPECIALIZATIONS_SORT_COLUMN_NAME"
  ],
  "ConciergeSpecializationsSortDirection": [
    "CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_ASC",
    "CONCIERGE_SPECIALIZATIONS_SORT_DIRECTION_DESC"
  ],
  "ContentCategory": [
    "CONTENT_CATEGORY_MARKETING",
    "CONTENT_CATEGORY_TRANSACTIONAL",
    "CONTENT_CATEGORY_UNSPECIFIED",
    "CONTENT_CATEGORY_WELCOME"
  ],
  "ContentClass": [
    "CONTENT_CLASS_FEATURE_ANNOUNCEMENT_MODAL",
    "CONTENT_CLASS_MODAL",
    "CONTENT_CLASS_NEXT_BEST_ACTIONS"
  ],
  "ContentMessageType": [
    "CONTENT_MESSAGE_TYPE_CAMPAIGN",
    "CONTENT_MESSAGE_TYPE_JOURNEY",
    "CONTENT_MESSAGE_TYPE_UNSPECIFIED"
  ],
  "ConversationSort": [
    "LATEST",
    "UNREAD"
  ],
  "ConversionsFeedCols": [
    "AMOUNT",
    "CLIENT_ID_CONVERSIONS",
    "CLIENT_USER_ID_CONVERSIONS",
    "COMPANY_ID_CONVERSIONS",
    "COUPON",
    "EMAIL_CONVERSIONS",
    "EVENT_UUID",
    "EXTERNAL_ID_CONVERSIONS",
    "MESSAGE_ID_CONVERSIONS",
    "MESSAGE_NAME_CONVERSIONS",
    "MESSAGE_SUBTYPE_CONVERSIONS",
    "MESSAGE_TEXT_CONVERSIONS",
    "MESSAGE_TYPE_CONVERSIONS",
    "ORDER_ID_CONVERSIONS",
    "PHONE_CONVERSIONS",
    "PRODUCT_SKUS",
    "QUANTITY_CONVERSIONS",
    "TIMESTAMP_CONVERSIONS",
    "UUID_CONVERSIONS"
  ],
  "CopyRecipientType": [
    "COPY_RECIPIENT_TYPE_BCC",
    "COPY_RECIPIENT_TYPE_CC"
  ],
  "CouponSetStatus": [
    "ACTIVE",
    "INACTIVE"
  ],
  "CouponSetType": [
    "DYNAMIC",
    "STATIC"
  ],
  "CouponType": [
    "CODE",
    "URL"
  ],
  "CouponUploadStatus": [
    "COUPON_UPLOAD_STATUS_UPLOAD_ACTIVE",
    "COUPON_UPLOAD_STATUS_UPLOAD_ERROR",
    "COUPON_UPLOAD_STATUS_UPLOAD_PENDING",
    "COUPON_UPLOAD_STATUS_UPLOAD_SYNCING"
  ],
  "CouponValueType": [
    "COUPON_VALUE_TYPE_FLAT_FIXED_CURRENCY",
    "COUPON_VALUE_TYPE_PERCENT"
  ],
  "CreatePaymentsMessageStatus": [
    "CREATE_PAYMENT_MESSAGE_STATUS_FAIL",
    "CREATE_PAYMENT_MESSAGE_STATUS_SUCCESS"
  ],
  "CreatePaymentsOfferStatus": [
    "CREATE_OFFER_STATUS_FAIL_COMPANY_NOT_CONFIGURED",
    "CREATE_OFFER_STATUS_FAIL_KEYWORD_IN_USE",
    "CREATE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE",
    "CREATE_OFFER_STATUS_FAIL_RESERVED_KEYWORD",
    "CREATE_OFFER_STATUS_SUCCESS"
  ],
  "CreativeCustomPropertyType": [
    "CREATIVE_CUSTOM_PROPERTY_TYPE_BOOL",
    "CREATIVE_CUSTOM_PROPERTY_TYPE_CHECKBOX",
    "CREATIVE_CUSTOM_PROPERTY_TYPE_DATE",
    "CREATIVE_CUSTOM_PROPERTY_TYPE_INPUT",
    "CREATIVE_CUSTOM_PROPERTY_TYPE_RADIO",
    "CREATIVE_CUSTOM_PROPERTY_TYPE_SELECT"
  ],
  "CreativeExperimentStatus": [
    "CREATIVE_EXPERIMENT_STATUS_ACTIVE",
    "CREATIVE_EXPERIMENT_STATUS_INACTIVE"
  ],
  "CreativeExperimentVisibility": [
    "CREATIVE_EXPERIMENT_VISIBILITY_EVERYONE",
    "CREATIVE_EXPERIMENT_VISIBILITY_SUPERUSER"
  ],
  "CreativeScheduleMemberStatus": [
    "CREATIVE_SCHEDULE_MEMBER_STATUS_ACTIVE",
    "CREATIVE_SCHEDULE_MEMBER_STATUS_INACTIVE"
  ],
  "CreativeScheduleStatus": [
    "CREATIVE_SCHEDULE_STATUS_COMPLETED",
    "CREATIVE_SCHEDULE_STATUS_NEXT_UP",
    "CREATIVE_SCHEDULE_STATUS_RUNNING"
  ],
  "CreativeScheduleType": [
    "CREATIVE_SCHEDULE_TYPE_ACTIVATE",
    "CREATIVE_SCHEDULE_TYPE_DEACTIVATE",
    "CREATIVE_SCHEDULE_TYPE_SWAP"
  ],
  "CreativeStatus": [
    "CREATIVE_STATUS_ACTIVE",
    "CREATIVE_STATUS_DRAFT",
    "CREATIVE_STATUS_INACTIVE"
  ],
  "CreativeStatusLabel": [
    "CREATIVE_STATUS_LABEL_ACTIVE",
    "CREATIVE_STATUS_LABEL_DRAFT",
    "CREATIVE_STATUS_LABEL_INACTIVE"
  ],
  "CreativeSubscriptionType": [
    "CREATIVE_SUBSCRIPTION_TYPE_CHECKOUT_ABANDONED",
    "CREATIVE_SUBSCRIPTION_TYPE_MARKETING",
    "CREATIVE_SUBSCRIPTION_TYPE_TRANSACTIONAL"
  ],
  "CreativeSubtype": [
    "CREATIVE_SUBTYPE_AFFILIATE_ENDPOINT",
    "CREATIVE_SUBTYPE_BUBBLE",
    "CREATIVE_SUBTYPE_BUBBLE_FULL_SCREEN",
    "CREATIVE_SUBTYPE_BUBBLE_PARTIAL",
    "CREATIVE_SUBTYPE_CODE",
    "CREATIVE_SUBTYPE_CONVERSATIONAL",
    "CREATIVE_SUBTYPE_COUPON_DISPLAY_SCREEN",
    "CREATIVE_SUBTYPE_DYNAMIC",
    "CREATIVE_SUBTYPE_EMAIL_BUBBLE_FULL_SCREEN",
    "CREATIVE_SUBTYPE_EMAIL_BUBBLE_PARTIAL",
    "CREATIVE_SUBTYPE_EMAIL_FULL_SCREEN",
    "CREATIVE_SUBTYPE_EMAIL_LIGHTBOX",
    "CREATIVE_SUBTYPE_EMAIL_PARTIAL",
    "CREATIVE_SUBTYPE_EMAIL_VENDOR_USER_PROFILE_SYNC",
    "CREATIVE_SUBTYPE_EMAIL_WIZARD",
    "CREATIVE_SUBTYPE_FULL_SCREEN",
    "CREATIVE_SUBTYPE_LANDING_PAGE",
    "CREATIVE_SUBTYPE_LIGHTBOX",
    "CREATIVE_SUBTYPE_LIGHTBOX_BUBBLE",
    "CREATIVE_SUBTYPE_LOYALTY",
    "CREATIVE_SUBTYPE_MANUAL_ENTRY",
    "CREATIVE_SUBTYPE_NO_SHOW",
    "CREATIVE_SUBTYPE_OFFER_DISPLAY",
    "CREATIVE_SUBTYPE_PARTIAL",
    "CREATIVE_SUBTYPE_PRIVACY",
    "CREATIVE_SUBTYPE_PRODUCT_DISPLAY",
    "CREATIVE_SUBTYPE_RECHARGE_CHECKOUT",
    "CREATIVE_SUBTYPE_RECHARGE_CHECKOUT_TRANSACTIONAL",
    "CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT",
    "CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_EMAIL",
    "CREATIVE_SUBTYPE_SHOPIFY_CHECKOUT_TRANSACTIONAL",
    "CREATIVE_SUBTYPE_TAG_ADD_TO_CART",
    "CREATIVE_SUBTYPE_TAG_PAGE_VIEW",
    "CREATIVE_SUBTYPE_TAG_PRODUCT_VIEW",
    "CREATIVE_SUBTYPE_TERMS",
    "CREATIVE_SUBTYPE_UNSUBSCRIBE",
    "CREATIVE_SUBTYPE_USER"
  ],
  "CreativeType": [
    "CREATIVE_TYPE_ABANDONMENT_AUTO_OPT_IN",
    "CREATIVE_TYPE_DESKTOP",
    "CREATIVE_TYPE_INTEGRATION",
    "CREATIVE_TYPE_LANDING_PAGE",
    "CREATIVE_TYPE_LEGAL",
    "CREATIVE_TYPE_MANUAL_UPLOAD",
    "CREATIVE_TYPE_MOBILE_PAYMENT",
    "CREATIVE_TYPE_ON_SITE",
    "CREATIVE_TYPE_TEXT_TO_JOIN"
  ],
  "CreativeVisibility": [
    "CREATIVE_VISIBILITY_EVERYONE",
    "CREATIVE_VISIBILITY_SUPERUSER"
  ],
  "CsvSortByField": [],
  "CurrencyCode": [
    "AED",
    "AFN",
    "ALL",
    "AMD",
    "ANG",
    "AOA",
    "ARS",
    "AUD",
    "AWG",
    "AZN",
    "BAM",
    "BBD",
    "BDT",
    "BGN",
    "BHD",
    "BIF",
    "BMD",
    "BND",
    "BOB",
    "BOV",
    "BRL",
    "BSD",
    "BTN",
    "BWP",
    "BYN",
    "BZD",
    "CAD",
    "CDF",
    "CHE",
    "CHF",
    "CHW",
    "CLF",
    "CLP",
    "CNY",
    "COP",
    "COU",
    "CRC",
    "CUC",
    "CUP",
    "CVE",
    "CZK",
    "DJF",
    "DKK",
    "DOP",
    "DZD",
    "EGP",
    "ERN",
    "ETB",
    "EUR",
    "FJD",
    "FKP",
    "GBP",
    "GEL",
    "GHS",
    "GIP",
    "GMD",
    "GNF",
    "GTQ",
    "GYD",
    "HKD",
    "HNL",
    "HRK",
    "HTG",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JMD",
    "JOD",
    "JPY",
    "KES",
    "KGS",
    "KHR",
    "KMF",
    "KPW",
    "KRW",
    "KWD",
    "KYD",
    "KZT",
    "LAK",
    "LBP",
    "LKR",
    "LRD",
    "LSL",
    "LYD",
    "MAD",
    "MDL",
    "MGA",
    "MKD",
    "MMK",
    "MNT",
    "MOP",
    "MRU",
    "MUR",
    "MVR",
    "MWK",
    "MXN",
    "MXV",
    "MYR",
    "MZN",
    "NAD",
    "NGN",
    "NIO",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PAB",
    "PEN",
    "PGK",
    "PHP",
    "PKR",
    "PLN",
    "PYG",
    "QAR",
    "RON",
    "RSD",
    "RUB",
    "RWF",
    "SAR",
    "SBD",
    "SCR",
    "SDG",
    "SEK",
    "SGD",
    "SHP",
    "SLE",
    "SLL",
    "SOS",
    "SRD",
    "SSP",
    "STN",
    "SVC",
    "SYP",
    "SZL",
    "THB",
    "TJS",
    "TMT",
    "TND",
    "TOP",
    "TRY",
    "TTD",
    "TWD",
    "TZS",
    "UAH",
    "UGX",
    "USD",
    "USN",
    "UYI",
    "UYU",
    "UYW",
    "UZS",
    "VED",
    "VES",
    "VND",
    "VUV",
    "WST",
    "XAF",
    "XAG",
    "XAU",
    "XBA",
    "XBB",
    "XBC",
    "XBD",
    "XCD",
    "XDR",
    "XOF",
    "XPD",
    "XPF",
    "XPT",
    "XSU",
    "XTS",
    "XUA",
    "XXX",
    "YER",
    "ZAR",
    "ZMW",
    "ZWL"
  ],
  "CustomEventKeyType": [
    "KEY_TYPE_ARRAY",
    "KEY_TYPE_BOOL",
    "KEY_TYPE_HYPERLINK",
    "KEY_TYPE_IMAGE",
    "KEY_TYPE_LOCAL_DATE",
    "KEY_TYPE_NUMBER",
    "KEY_TYPE_OBJECT",
    "KEY_TYPE_STRING"
  ],
  "CustomProductPropertyType": [
    "CUSTOM_PRODUCT_PROPERTY_TYPE_PRODUCT_VARIANT"
  ],
  "CustomPropertyType": [
    "TYPE_ARRAY_OBJECT",
    "TYPE_BOOL",
    "TYPE_DATE",
    "TYPE_DATETIME",
    "TYPE_ENUM",
    "TYPE_LONG",
    "TYPE_NUMBER",
    "TYPE_OBJECT",
    "TYPE_STRING",
    "TYPE_TIMESTAMP"
  ],
  "DatasetFilterType": [
    "DATASET_FILTER_TYPE_BOOLEAN",
    "DATASET_FILTER_TYPE_NUMERIC",
    "DATASET_FILTER_TYPE_STRING",
    "DATASET_FILTER_TYPE_TIME"
  ],
  "DatasetOrderByDirection": [
    "DATASET_ORDER_BY_DIRECTION_ASC",
    "DATASET_ORDER_BY_DIRECTION_DESC"
  ],
  "DatastreamPriority": [
    "DATASTREAM_PRIORITY_P0",
    "DATASTREAM_PRIORITY_P1",
    "DATASTREAM_PRIORITY_P2",
    "DATASTREAM_PRIORITY_P3"
  ],
  "DatastreamStatus": [
    "DATASTREAM_STATUS_OFF",
    "DATASTREAM_STATUS_ON"
  ],
  "DateFilterPeriod": [
    "DATE_FILTER_PERIOD_LAST_7_DAYS",
    "DATE_FILTER_PERIOD_LAST_30_DAYS",
    "DATE_FILTER_PERIOD_LAST_90_DAYS",
    "DATE_FILTER_PERIOD_LAST_MONTH",
    "DATE_FILTER_PERIOD_LAST_QUARTER",
    "DATE_FILTER_PERIOD_LAST_YEAR",
    "DATE_FILTER_PERIOD_MONTH_TO_DATE",
    "DATE_FILTER_PERIOD_QUARTER_TO_DATE",
    "DATE_FILTER_PERIOD_TODAY",
    "DATE_FILTER_PERIOD_WEEK_TO_DATE",
    "DATE_FILTER_PERIOD_YEAR_TO_DATE",
    "DATE_FILTER_PERIOD_YESTERDAY"
  ],
  "DiscountLinkFormat": [
    "DISCOUNT_LINK_FORMAT_CODE_IN_PATH",
    "DISCOUNT_LINK_FORMAT_CODE_IN_QUERY",
    "DISCOUNT_LINK_FORMAT_NONE"
  ],
  "DiscountStatus": [
    "ACTIVE",
    "EXPIRED",
    "SCHEDULED",
    "UNKNOWN_STATUS"
  ],
  "DiscountType": [
    "BUY_X_GET_Y",
    "FREE_SHIPPING",
    "PERCENTAGE_OFF_QUANTITY",
    "PERCENTAGE_OFF_TOTAL",
    "PER_ITEM_DISCOUNT",
    "PER_TOTAL_DISCOUNT",
    "UNKNOWN_DISCOUNT_TYPE"
  ],
  "DistanceUnit": [
    "DISTANCE_UNIT_KILOMETER",
    "DISTANCE_UNIT_METER",
    "DISTANCE_UNIT_MILE"
  ],
  "DistributionStatus": [
    "BETA",
    "PRIVATE",
    "PUBLIC",
    "UNLISTED"
  ],
  "DistributionUnit": [
    "DISTRIBUTION_UNIT_DAY",
    "DISTRIBUTION_UNIT_HOUR",
    "DISTRIBUTION_UNIT_MONTH",
    "DISTRIBUTION_UNIT_WEEK"
  ],
  "DnsRecordType": [
    "DNS_RECORD_TYPE_CNAME",
    "DNS_RECORD_TYPE_MX",
    "DNS_RECORD_TYPE_TXT"
  ],
  "E2ETestMessageType": [
    "E2E_TEST_MESSAGE_TYPE_BROWSE_ABANDONMENT",
    "E2E_TEST_MESSAGE_TYPE_CART_ABANDONMENT",
    "E2E_TEST_MESSAGE_TYPE_LEGAL",
    "E2E_TEST_MESSAGE_TYPE_SESSION_ABANDONMENT",
    "E2E_TEST_MESSAGE_TYPE_WELCOME",
    "E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_1",
    "E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_2",
    "E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_3",
    "E2E_TEST_MESSAGE_TYPE_WELCOME_FOLLOWUP_4"
  ],
  "EditorSource": [
    "EDITOR_SOURCE_DRAG_AND_DROP",
    "EDITOR_SOURCE_HTML"
  ],
  "EdsJobIngestionFormat": [
    "EDS_JOB_INGESTION_FORMAT_CSV",
    "EDS_JOB_INGESTION_FORMAT_NDJSON",
    "EDS_JOB_INGESTION_FORMAT_PSV",
    "EDS_JOB_INGESTION_FORMAT_TSV",
    "EDS_JOB_INGESTION_FORMAT_XML"
  ],
  "EdsJobSync": [
    "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES",
    "EDS_JOB_SYNC_CUSTOM_EVENTS",
    "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS",
    "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS",
    "EDS_JOB_SYNC_ECOMMERCE_PURCHASES",
    "EDS_JOB_SYNC_MOBILE_WALLET",
    "EDS_JOB_SYNC_PRODUCT_CATALOG",
    "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS",
    "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS",
    "EDS_JOB_SYNC_TEXT_SEND",
    "EDS_JOB_SYNC_TEXT_SEND_BULK",
    "EDS_JOB_SYNC_USER_IDENTIFIERS"
  ],
  "EdsJobSyncMethod": [
    "EDS_JOB_SYNC_METHOD_AMAZON_S3",
    "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP",
    "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP",
    "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD",
    "EDS_JOB_SYNC_METHOD_S3",
    "EDS_JOB_SYNC_METHOD_SFTP",
    "EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE"
  ],
  "Element": [
    "BRANDKIT_ELEMENT_BACKGROUND",
    "BRANDKIT_ELEMENT_BUTTON",
    "BRANDKIT_ELEMENT_BUTTON_TEXT",
    "BRANDKIT_ELEMENT_TEXT"
  ],
  "EmailAccountSort": [
    "EMAIL_ACCOUNT_SORT_LATEST",
    "EMAIL_ACCOUNT_SORT_OLDEST"
  ],
  "EmailIPAddressWarmupStatus": [
    "EMAIL_IP_ADDRESS_WARMUP_STATUS_DONE",
    "EMAIL_IP_ADDRESS_WARMUP_STATUS_IN_PROGRESS",
    "EMAIL_IP_ADDRESS_WARMUP_STATUS_NOT_STARTED"
  ],
  "EmailIntegrationLevel": [
    "OFF",
    "TRIGGERED_AND_CAMPAIGN_EMAIL",
    "TRIGGERED_EMAIL_ONLY"
  ],
  "EmailProvider": [
    "EMAIL_PROVIDER_SENDGRID",
    "EMAIL_PROVIDER_SFMC"
  ],
  "EncryptionType": [
    "ENCRYPTION_TYPE_PGP"
  ],
  "EnhancementMetric": [
    "ENHANCEMENT_METRIC_REV",
    "ENHANCEMENT_METRIC_ROI"
  ],
  "EnhancementMetricChange": [
    "ENHANCEMENT_METRIC_CHANGE_HIGHER",
    "ENHANCEMENT_METRIC_CHANGE_LOWER",
    "ENHANCEMENT_METRIC_CHANGE_MAXIMIZED",
    "ENHANCEMENT_METRIC_CHANGE_SIMILAR"
  ],
  "EnhancementType": [
    "ENHANCEMENT_TYPE_BLENDED",
    "ENHANCEMENT_TYPE_EXCLUSION",
    "ENHANCEMENT_TYPE_REVENUE_BOOSTING",
    "ENHANCEMENT_TYPE_REVENUE_MAXIMIZING",
    "ENHANCEMENT_TYPE_ROI_BOOSTING",
    "ENHANCEMENT_TYPE_ROI_MAXIMIZING"
  ],
  "EntityType": [
    "ENTITY_TYPE_COMPANY_APPLICATION",
    "ENTITY_TYPE_COMPANY_UPLOAD",
    "ENTITY_TYPE_COMPANY_VENDOR_INTEGRATION",
    "ENTITY_TYPE_CONVERSATIONAL",
    "ENTITY_TYPE_CREATIVE",
    "ENTITY_TYPE_JOURNEYS",
    "ENTITY_TYPE_PUBLIC_API_APPLICATION",
    "ENTITY_TYPE_SEND_SERVICE",
    "ENTITY_TYPE_SHOPIFY_SERVER_PIXEL",
    "ENTITY_TYPE_TAG"
  ],
  "EscalationsSortColumn": [
    "ESCALATIONS_SORT_COLUMN_DATE_TIMESTAMP",
    "ESCALATIONS_SORT_COLUMN_UNKNOWN_COLUMN"
  ],
  "EscalationsSortDirection": [
    "ESCALATIONS_SORT_DIRECTION_ASC",
    "ESCALATIONS_SORT_DIRECTION_DESC",
    "ESCALATIONS_SORT_UNKNOWN_DIRECTION"
  ],
  "EtlConfigFeature": [
    "ETL_CONFIG_FEATURE_CONVERSIONS",
    "ETL_CONFIG_FEATURE_GENERAL_DATA_FEED"
  ],
  "EtlEnabled": [
    "CUSTOM_DAG",
    "DISABLED",
    "ENABLED"
  ],
  "EventLookupType": [
    "EVENT_LOOKUP_TYPE_BY_UUID",
    "EVENT_LOOKUP_TYPE_DYNAMIC_FILTERS"
  ],
  "EventReplayEventType": [
    "EMAIL_SAVE",
    "JOIN",
    "JOURNEY_POSTBACK",
    "MESSAGE_LINK_CLICK",
    "MESSAGE_RECEIPT",
    "SUBSCRIPTION_ALREADY_SUBSCRIBED",
    "SUBSCRIPTION_OPTED_OUT",
    "SUBSCRIPTION_OPT_IN_COMPLETED",
    "SUBSCRIPTION_OPT_IN_STARTED",
    "USER_PROPERTY_SET"
  ],
  "EventReplayStatus": [
    "ANALYZING",
    "COMPLETED",
    "FAILED",
    "IN_PROGRESS",
    "PENDING",
    "QUEUED",
    "READY"
  ],
  "EventType": [
    "ADD_TO_CART",
    "ALERT",
    "ATTENTIVE_CDC",
    "CAMPAIGN_CANCELLED",
    "CAMPAIGN_CREATED",
    "CAMPAIGN_DELETED",
    "CAMPAIGN_DOWNGRADED",
    "CAMPAIGN_DRAFTED",
    "CAMPAIGN_MMS_RATE_LIMIT_UPDATED",
    "CAMPAIGN_NEEDS_COUPONS",
    "CAMPAIGN_PAUSED",
    "CAMPAIGN_PAW_AUDIENCE_SIZE_ZERO",
    "CAMPAIGN_PICK_A_WINNER_FAILED",
    "CAMPAIGN_RATE_LIMIT_UPDATED",
    "CAMPAIGN_REPLAY_CONFIRMED",
    "CAMPAIGN_REPLAY_REQUESTED",
    "CAMPAIGN_RESCHEDULED",
    "CAMPAIGN_RESUMED",
    "CAMPAIGN_SEND_FINISHED",
    "CAMPAIGN_SEND_STARTED",
    "CAMPAIGN_SMS_RATE_LIMIT_UPDATED",
    "CAMPAIGN_SUBSCRIBER_QUALIFICATION_EVALUATED",
    "CAMPAIGN_TIMEZONE_SEND_FINISHED",
    "CAMPAIGN_TIMEZONE_SEND_STARTED",
    "CAMPAIGN_UPDATED",
    "CARRIER_DATA_RECEIVED",
    "CART_UPDATED",
    "CART_VIEWED",
    "CHECKOUT",
    "CHECKOUT_ABANDONED",
    "CHECKOUT_STARTED",
    "CHECKOUT_UPDATED",
    "CLICK",
    "COLLECTION_VIEWED",
    "COMPANY_LINK_CLICK",
    "CONVERSION",
    "CONVERSION_FOR_MESSAGE",
    "COUPON_ASSIGNED",
    "COUPON_SCREEN_LOAD",
    "CREATIVE_INTERACTION",
    "CUSTOMER_SERVICE_TICKET_CLOSED",
    "CUSTOMER_SERVICE_TICKET_OPENED",
    "CUSTOM_EVENT",
    "DRUID_MESSAGE_EXPLORER_DATA",
    "EDS_TELEMETRY_TRACER",
    "EMAIL_ACTIVITY",
    "EMAIL_MESSAGE_OPENED",
    "EMAIL_MESSAGE_SENT",
    "EMAIL_SAVE",
    "EMAIL_SPAM_REPORTED",
    "EMAIL_STATUS_UPDATED",
    "ENRICHED_DLR",
    "EVENT_CONSUMED",
    "FULFILLMENT_AVAILABLE_FOR_PICKUP",
    "FULFILLMENT_CANCELLED",
    "FULFILLMENT_CREATED",
    "FULFILLMENT_DELIVERED",
    "FULFILLMENT_DELIVERY_ATTEMPT_FAILURE",
    "FULFILLMENT_DELIVERY_EXCEPTION",
    "FULFILLMENT_IN_TRANSIT",
    "FULFILLMENT_OUT_FOR_DELIVERY",
    "FULFILLMENT_PENDING",
    "IMPRESSION",
    "INVENTORY_LISTING",
    "JOIN",
    "JOURNEY_POSTBACK",
    "JOURNEY_REVISION_PUBLISHED",
    "JOURNEY_TASK_EXECUTED",
    "JOURNEY_TRIGGERED",
    "LAST_CLICK_CONVERSION",
    "LOYALTY_OPTED_IN",
    "LOYALTY_OPTED_OUT",
    "LOYALTY_POINTS_CHANGED",
    "LOYALTY_POINT_BALANCE_REMINDER_ISSUED",
    "LOYALTY_REFERRAL_COMPLETED",
    "LOYALTY_REWARD_REDEEMED",
    "LOYALTY_REWARD_REDEMPTION_REMINDER_ISSUED",
    "LOYALTY_TIER_CHANGED",
    "LOYALTY_TIER_PROGRESS_UPDATED",
    "LOYALTY_USER_SPECIAL_DAY_REACHED",
    "MESSAGE_EXPLORER_CONTENT_CREATED",
    "MESSAGE_EXPLORER_CREATED",
    "MESSAGE_EXPLORER_UPDATED",
    "MESSAGE_FATIGUE",
    "MESSAGE_LINK_CLICK",
    "MESSAGE_LINK_CREATED",
    "MESSAGE_LINK_PREFETCH",
    "MESSAGE_METADATA_CONTENT",
    "MESSAGE_METADATA_FINALIZED",
    "MESSAGE_RECEIPT",
    "MESSAGE_STATUS_DETAILS",
    "MOBILE_WALLET_COUPON_INSTALLED",
    "MOBILE_WALLET_COUPON_UNINSTALLED",
    "MULTI_PROPERTY_SET",
    "OPT_OUT",
    "OPT_OUT_SUBSCRIPTION",
    "ORDER",
    "ORDER_CANCELLED",
    "ORDER_CONFIRMED",
    "ORDER_SUBSCRIPTION_CHARGE_FAILED",
    "ORDER_SUBSCRIPTION_CHARGE_PAID",
    "ORDER_SUBSCRIPTION_CHARGE_SCHEDULED",
    "ORDER_SUBSCRIPTION_CREATED",
    "ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_FAILED",
    "ORDER_SUBSCRIPTION_ONE_TIME_PRODUCT_ADD_SUCCEEDED",
    "ORDER_SUBSCRIPTION_PROCESSED",
    "ORDER_SUBSCRIPTION_SKIPPED",
    "PAGE_LEAVE",
    "PAGE_VIEW",
    "PAYMENTS_BUY_RESPONSE_RECEIVED",
    "PAYMENTS_CALL_TO_ACTION_SENT",
    "PAYMENTS_CARD_VAULTED",
    "PAYMENTS_ORDER_CANCELLED",
    "PAYMENTS_ORDER_CANCEL_REQUESTED",
    "PAYMENTS_PURCHASE_COMPLETED",
    "PAYMENTS_PURCHASE_REFUNDED",
    "PAYMENTS_SESSION_FAILED",
    "PAYMENTS_SESSION_STARTED",
    "PRINCIPAL_ACTION_OCCURRED",
    "PROBE",
    "PRODUCT_INVENTORY_CHANGE",
    "PRODUCT_RECOMMENDATION",
    "PRODUCT_VIEW",
    "PUBLIC_API_REQUEST",
    "PURCHASE",
    "REFUND_FAILED",
    "REFUND_SUCCEEDED",
    "REMOVED_FROM_CART",
    "REMOVE_FROM_CART",
    "REPLENISHMENT",
    "RESUBSCRIBE",
    "REVIEW_SUBMITTED",
    "SEARCH_ATTRIBUTE_SET",
    "SEARCH_SUBMITTED",
    "SEGMENT_JOINED",
    "SEND_METADATA_CREATED",
    "SEND_METADATA_UPDATED",
    "SEND_STATUS_UPDATED",
    "SHARED_IP_EVENT_SNAPSHOT",
    "SUBSCRIBER_DOUBLE_OPT_IN_STARTED",
    "SUBSCRIBER_FATIGUED",
    "SUBSCRIBER_LIST",
    "SUBSCRIBER_PURCHASE_KEYWORD_RECEIVED",
    "SUBSCRIBER_TAG",
    "SUBSCRIPTION_ALREADY_SUBSCRIBED",
    "SUBSCRIPTION_CONTACT_INFO_REASSIGNED",
    "SUBSCRIPTION_DEACTIVATED",
    "SUBSCRIPTION_DEACTIVATED_SKIPPED",
    "SUBSCRIPTION_OPTED_OUT",
    "SUBSCRIPTION_OPT_IN_COMPLETED",
    "SUBSCRIPTION_OPT_IN_DECLINED",
    "SUBSCRIPTION_OPT_IN_STARTED",
    "SUBSCRIPTION_POINT_IN_TIME_METRIC",
    "SUBSCRIPTION_SUPPRESSED",
    "SUBSCRIPTION_UNSUPPRESSED",
    "SURVEY_KEYWORD_RECEIVED",
    "TEXT_MESSAGE_RECEIVED",
    "TRACE_EVENT",
    "TRINO_QUERY",
    "USER_IDENTIFIER_COLLECTED",
    "USER_PROPERTY_BATCH",
    "USER_PROPERTY_DEFINITION_CHANGE",
    "USER_PROPERTY_MUTATE",
    "USER_PROPERTY_SET",
    "USER_PROPERTY_VALUE_CHANGE",
    "VARIANT_INVENTORY_CHANGE",
    "VARIANT_PRICE_CHANGE"
  ],
  "ExperimentType": [
    "EXPERIMENT_EMAIL_CONTENT",
    "EXPERIMENT_EMAIL_SUBJECT_LINE_AND_PREVIEW_TEXT",
    "EXPERIMENT_SEND_TIME",
    "EXPERIMENT_SUBSCRIBER_OPTIMIZED_SEND_TIME",
    "EXPERIMENT_TEXT_CONTENT"
  ],
  "ExternalVendorDataField": [
    "EXTERNAL_DATA_FIELD_FACEBOOK_ADS_AD_ACCOUNTS",
    "EXTERNAL_DATA_FIELD_FACEBOOK_ADS_CUSTOM_AUDIENCES"
  ],
  "Feature": [
    "BRIDGE_IDENTIFIERS",
    "CUSTOMER_SERVICE_OPT_IN",
    "DYNAMIC_COUPON_GENERATION",
    "FEATURE_ADDITIONAL_SETUP_LINK",
    "FEATURE_ATTENTIVE_LOYALTY_SIGNUP",
    "FEATURE_AUTO_INSTALL_TAG",
    "FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS",
    "FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS",
    "FEATURE_EMAIL_OPT_OUT_SYNC",
    "FEATURE_EXPORT_SEGMENTS",
    "FEATURE_GENERATE_HOOK",
    "FEATURE_INGEST_AD_DATA",
    "FEATURE_INGEST_LEADS",
    "FEATURE_INGEST_TAG_EVENTS",
    "FEATURE_INGEST_USER_DATA",
    "FEATURE_MARKETING_EMAIL_MESSAGE_SEND",
    "FEATURE_POSTBACK_USER_ATTRIBUTES",
    "FEATURE_POSTBACK_USER_DATA",
    "FEATURE_POST_SUBSCRIBER_OPT_IN",
    "FEATURE_POST_SUBSCRIBER_OPT_OUT",
    "FEATURE_PURCHASE_PRODUCT",
    "FEATURE_REFUND_SYNC",
    "FEATURE_SETUP_REDIRECT_SOURCE",
    "FEATURE_SUBSCRIPTION_MESSAGING",
    "FEATURE_SURVEY_MESSAGE",
    "FEATURE_SYNC_ABANDONED_CHECKOUTS",
    "FEATURE_SYNC_ACCOUNT_PROPERTIES",
    "FEATURE_SYNC_ATTENTIVE_TO_ESP_EMAIL_OPT_OUTS_VIA_SFTP",
    "FEATURE_SYNC_EMAIL_EVENTS",
    "FEATURE_SYNC_EMAIL_SUBSCRIBERS",
    "FEATURE_SYNC_ESP_TO_ATTENTIVE_EMAIL_OPT_OUTS_VIA_SFTP",
    "FEATURE_SYNC_LIST_COUNT",
    "FEATURE_SYNC_LIST_IDENTIFIERS",
    "FEATURE_SYNC_LOYALTY_EVENTS",
    "FEATURE_SYNC_ORDER_EVENTS",
    "FEATURE_SYNC_PRODUCT_COLLECTIONS",
    "FEATURE_SYNC_PURCHASES",
    "FEATURE_SYNC_RECURRING_PURCHASES",
    "FEATURE_SYNC_REVIEWS",
    "FEATURE_SYNC_SMS_CONSENT",
    "FEATURE_SYNC_THIRD_PARTY_CAMPAIGNS",
    "FEATURE_SYNC_THIRD_PARTY_JOURNEYS",
    "FEATURE_SYNC_USER_PROFILES",
    "FEATURE_TEMPLATIZED_POSTBACK",
    "FEATURE_TRIGGER_VENDOR_EMAIL_SEND",
    "FORWARD_MESSAGE_CUSTOMER_SERVICE",
    "INGEST_TRANSACTIONAL_EVENTS",
    "POSTBACK_EMAILS",
    "POST_WITH_PHONE",
    "SCRUB_MESSAGE_SIGNATURE",
    "SYNC_HISTORICAL_PURCHASES",
    "SYNC_PRODUCTS",
    "SYNC_THIRD_PARTY_LISTS",
    "SYNC_THIRD_PARTY_SEGMENTS",
    "THIRD_PARTY_PROCESSOR_FORWARDER"
  ],
  "FilterOperation": [
    "FILTER_OPERATION_AND",
    "FILTER_OPERATION_OR"
  ],
  "FilterOperator": [
    "FILTER_OPERATOR_EQUALS",
    "FILTER_OPERATOR_IN",
    "FILTER_OPERATOR_NOT_EQUALS",
    "FILTER_OPERATOR_NOT_IN"
  ],
  "FlinkDeploymentMode": [
    "FLINK_DEPLOYMENT_MODE_APPLICATION_MODE",
    "FLINK_DEPLOYMENT_MODE_SESSION_MODE"
  ],
  "FloridaSubscribersAuditStatusV2": [
    "FLORIDA_SUBSCRIBERS_AUDIT_STATUS_AUDIT_REPORT_RUNNING",
    "FLORIDA_SUBSCRIBERS_AUDIT_STATUS_FAILURE"
  ],
  "FontType": [
    "BRANDKIT_FONT_TYPE_CUSTOM",
    "BRANDKIT_FONT_TYPE_GOOGLE",
    "BRANDKIT_FONT_TYPE_TYPEKIT",
    "BRANDKIT_FONT_TYPE_WEBSAFE"
  ],
  "GeneralDataFeedCols": [
    "CAMPAIGN_NAME",
    "CAMPAIGN_TYPE",
    "CLIENT_ID_GENERAL_DATA_FEED",
    "CLIENT_USER_ID_GENERAL_DATA_FEED",
    "COMPANY_ID_GENERAL_DATA_FEED",
    "COUPONS",
    "CREATIVE_ID",
    "CREATIVE_NAME",
    "CREATIVE_SUBTYPE",
    "CREATIVE_TYPE",
    "DATE",
    "EMAIL",
    "EXTERNAL_ID_GENERAL_DATA_FEED",
    "HAS_MEDIA",
    "HOLDOUT_GROUP",
    "JOIN_SOURCE",
    "MESSAGE_GID",
    "MESSAGE_ID_GENERAL_DATA_FEED",
    "MESSAGE_LINK_TARGET_GENERAL_DATA_FEED",
    "MESSAGE_NAME_GENERAL_DATA_FEED",
    "MESSAGE_SID",
    "MESSAGE_START",
    "MESSAGE_SUBTYPE_GENERAL_DATA_FEED",
    "MESSAGE_TEXT_GENERAL_DATA_FEED",
    "MESSAGE_TYPE_GENERAL_DATA_FEED",
    "OPT_IN_FLAG",
    "OPT_IN_SMS",
    "ORDER_ID_GENERAL_DATA_FEED",
    "PARENT_MESSAGE_NAME",
    "PHONE_COUNTRY_GENERAL_DATA_FEED",
    "PHONE_GENERAL_DATA_FEED",
    "PRODUCT_ID_GENERAL_DATA_FEED",
    "PRODUCT_NAME",
    "PRODUCT_PRICE",
    "PRODUCT_SKU",
    "PROPERTY_ID",
    "PROPERTY_NAME",
    "PROPERTY_VALUE",
    "PROPERTY_VALUE_ID",
    "QUANTITY_GENERAL_DATA_FEED",
    "RAW_EXTERNAL_ID_GENERAL_DATA_FEED",
    "REGION_GENERAL_DATA_FEED",
    "SIGNUP_URL_GENERAL_DATA_FEED",
    "SMS_ACQ_SOURCE",
    "SOURCE",
    "SUBSCRIBER_ID",
    "SUBSCRIBER_KEY",
    "SUBSCRIBER_VAL",
    "SUBSCRIPTION_CHANNEL",
    "SUBSCRIPTION_TYPE",
    "TIMESTAMP_GENERAL_DATA_FEED",
    "TOTAL",
    "TTJ_SOURCE",
    "TYPE",
    "USER_ID",
    "UUID_GENERAL_DATA_FEED",
    "VISITOR_ID",
    "VISITOR_PHONE",
    "ZIPCODE"
  ],
  "GenerativeAITextFeedbackCategory": [
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BAD_GRAMMAR",
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_BRAND_VOICE_MISMATCH",
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_CONTENT_PROMPT_MISMATCH",
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_INCLUDES_PII",
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OFFENSIVE_LANGUAGE",
    "GENERATIVE_AI_TEXT_FEEDBACK_CATEGORY_OTHER"
  ],
  "GenerativeAITextFeedbackType": [
    "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_A_B_WINNER",
    "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_FLAGGED",
    "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_GENERATE_MORE_SIMILAR",
    "GENERATIVE_AI_TEXT_FEEDBACK_TYPE_RANKED_WINNER"
  ],
  "GetCreativeSchedulesSortDirection": [
    "CREATIVE_SCHEDULE_SORT_DIRECTION_ASC",
    "CREATIVE_SCHEDULE_SORT_DIRECTION_DESC"
  ],
  "GetCreativeSchedulesSortField": [
    "CREATIVE_SCHEDULE_SORT_FIELD_END_TIME",
    "CREATIVE_SCHEDULE_SORT_FIELD_NAME",
    "CREATIVE_SCHEDULE_SORT_FIELD_START_TIME",
    "CREATIVE_SCHEDULE_SORT_FIELD_STATUS"
  ],
  "HasVerb": [
    "HAS_VERB_HAS",
    "HAS_VERB_HAS_NOT"
  ],
  "HttpRequestType": [
    "DELETE",
    "GET",
    "PATCH",
    "POST",
    "PUT"
  ],
  "IPPoolType": [
    "IP_POOL_TYPE_CAMPAIGN",
    "IP_POOL_TYPE_TRANSACTIONAL",
    "IP_POOL_TYPE_TRIGGERED"
  ],
  "ImageGenerationType": [
    "IMAGE_GENERATION_TYPE_SCENE_CREATION"
  ],
  "ImageOrientation": [
    "IMAGE_ORIENTATION_AUTO",
    "IMAGE_ORIENTATION_LANDSCAPE",
    "IMAGE_ORIENTATION_PORTRAIT",
    "IMAGE_ORIENTATION_SQUARE"
  ],
  "ImageStyle": [
    "IMAGE_STYLE_DARK",
    "IMAGE_STYLE_LIGHT"
  ],
  "ImageType": [
    "IMAGE_TYPE_GENERIC",
    "IMAGE_TYPE_LOGO"
  ],
  "IneligibilityReason": [
    "INELIGIBILITY_REASON_AUTO_SUPPRESSED",
    "INELIGIBILITY_REASON_KNOWN_LITIGATOR",
    "INELIGIBILITY_REASON_NOT_FULLY_OPTED_IN",
    "INELIGIBILITY_REASON_OPTED_OUT",
    "INELIGIBILITY_REASON_USER_SUPPRESSED"
  ],
  "IntegrationErrorType": [
    "INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_ATTENTIVE_CREDENTIALS",
    "INTEGRATION_ERROR_TYPE_AUTHENTICATION_BAD_VENDOR_CREDENTIALS",
    "INTEGRATION_ERROR_TYPE_AUTHORIZATION_PERMISSIONS_INSUFFICIENT",
    "INTEGRATION_ERROR_TYPE_CONFIGURATION_SHOPIFY_PLAN_STATUS_MISMATCH",
    "INTEGRATION_ERROR_TYPE_EMAIL_LIST_NOT_FOUND",
    "INTEGRATION_ERROR_TYPE_LOW_PRODUCT_ID_MATCH_RATE",
    "INTEGRATION_ERROR_TYPE_SHOPIFY_UNAVAILABLE_SHOP_NAME"
  ],
  "IntegrationType": [
    "CUSTOM_INTEGRATION",
    "INTEGRATION_NOT_SPECIFIED",
    "OVERRIDE_INTEGRATION"
  ],
  "InventoryComparator": [
    "INVENTORY_COMPARATOR_GREATER_THAN",
    "INVENTORY_COMPARATOR_LESS_THAN"
  ],
  "IsVerb": [
    "IS_VERB_IS",
    "IS_VERB_IS_NOT"
  ],
  "JobHealth": [
    "JOBHEALTH_HEALTHY",
    "JOBHEALTH_UNHEALTHY_JOB_BACKPRESSURE",
    "JOBHEALTH_UNHEALTHY_RECENT_ERROR",
    "JOBHEALTH_UNHEALTHY_YARN_APP_FAILURE"
  ],
  "JobStatus": [
    "JOBSTATUS_FLAPPING",
    "JOBSTATUS_RUNNING",
    "JOBSTATUS_STARTING",
    "JOBSTATUS_STOPPED"
  ],
  "JourneyChannel": [
    "JOURNEY_CHANNEL_EMAIL",
    "JOURNEY_CHANNEL_MULTI",
    "JOURNEY_CHANNEL_SMS"
  ],
  "JourneyDurationUnit": [
    "JOURNEY_DURATION_UNIT_DAYS",
    "JOURNEY_DURATION_UNIT_HOURS",
    "JOURNEY_DURATION_UNIT_MINUTES",
    "JOURNEY_DURATION_UNIT_WEEKS"
  ],
  "JourneyEventOrigin": [
    "JOURNEY_EVENT_ORIGIN_ATTENTIVE_TAG",
    "JOURNEY_EVENT_ORIGIN_INTEGRATIONS",
    "JOURNEY_EVENT_ORIGIN_SMS"
  ],
  "JourneyNodeType": [
    "JOURNEY_NODE_TYPE_CREATE_SUPPORT_TICKET",
    "JOURNEY_NODE_TYPE_FORWARD_TO_CONCIERGE",
    "JOURNEY_NODE_TYPE_INCOMING_TEXT",
    "JOURNEY_NODE_TYPE_KEYWORD_RESPONSE",
    "JOURNEY_NODE_TYPE_OPEN_RESPONSE",
    "JOURNEY_NODE_TYPE_SAVE_USER_ATTRIBUTE",
    "JOURNEY_NODE_TYPE_SEND_CONVERSATION_MESSAGE",
    "JOURNEY_NODE_TYPE_SEND_EMAIL",
    "JOURNEY_NODE_TYPE_SEND_LEGAL_WELCOME",
    "JOURNEY_NODE_TYPE_SEND_MESSAGE",
    "JOURNEY_NODE_TYPE_SEND_PAYMENT_MESSAGE",
    "JOURNEY_NODE_TYPE_TRIGGER",
    "JOURNEY_NODE_TYPE_TRIGGER_INTEGRATION_POSTBACK",
    "JOURNEY_NODE_TYPE_TWO_WAY_MESSAGE",
    "JOURNEY_NODE_TYPE_VARIANT"
  ],
  "JourneyRevisionPublishedStatus": [
    "JOURNEY_REVISION_PUBLISHED_STATUS_ACTIVE",
    "JOURNEY_REVISION_PUBLISHED_STATUS_DRAFT",
    "JOURNEY_REVISION_PUBLISHED_STATUS_INACTIVE",
    "JOURNEY_REVISION_PUBLISHED_STATUS_SCHEDULED"
  ],
  "JourneyRuntimeErrorType": [
    "JOURNEY_RUNTIME_ERROR_TYPE_COUPON_POOL_EXHAUSTED",
    "JOURNEY_RUNTIME_ERROR_TYPE_REPLY_TO_BUY_API_KEY_INVALID",
    "JOURNEY_RUNTIME_ERROR_TYPE_USER_PROFILE_ATTRIBUTE_DELETED"
  ],
  "JourneyScheduleStatus": [
    "JOURNEY_SCHEDULE_STATUS_CANCELLED",
    "JOURNEY_SCHEDULE_STATUS_COMPLETED",
    "JOURNEY_SCHEDULE_STATUS_SCHEDULED"
  ],
  "JourneyState": [
    "JOURNEY_STATE_ACTIVE",
    "JOURNEY_STATE_DRAFT",
    "JOURNEY_STATE_INACTIVE"
  ],
  "JourneyTagDisplayType": [
    "JOURNEY_TAG_DISPLAY_TYPE_DEFAULT",
    "JOURNEY_TAG_DISPLAY_TYPE_UNSPECIFIED"
  ],
  "JourneyTriggerType": [
    "JOURNEY_TRIGGER_TYPE_ADD_TO_CART",
    "JOURNEY_TRIGGER_TYPE_BACK_IN_STOCK",
    "JOURNEY_TRIGGER_TYPE_CUSTOM_EVENT",
    "JOURNEY_TRIGGER_TYPE_INTEGRATION_EVENT",
    "JOURNEY_TRIGGER_TYPE_JOINED_A_SEGMENT",
    "JOURNEY_TRIGGER_TYPE_LOW_INVENTORY",
    "JOURNEY_TRIGGER_TYPE_OPT_IN_BACK_IN_STOCK",
    "JOURNEY_TRIGGER_TYPE_ORDER_CREATED",
    "JOURNEY_TRIGGER_TYPE_ORDER_FULFILLED",
    "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CHARGE_FAILED",
    "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_CREATED",
    "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_SKIPPED",
    "JOURNEY_TRIGGER_TYPE_ORDER_SUBSCRIPTION_UPCOMING_CHARGE",
    "JOURNEY_TRIGGER_TYPE_PAGE_VIEW",
    "JOURNEY_TRIGGER_TYPE_PRICE_DROP",
    "JOURNEY_TRIGGER_TYPE_PRODUCT_VIEW",
    "JOURNEY_TRIGGER_TYPE_PURCHASE",
    "JOURNEY_TRIGGER_TYPE_SIGNED_UP",
    "JOURNEY_TRIGGER_TYPE_SIGNED_UP_FOR_EMAIL",
    "JOURNEY_TRIGGER_TYPE_TEXTED_A_KEYWORD",
    "JOURNEY_TRIGGER_TYPE_USER_WIN_BACK"
  ],
  "KnownProductPropertyType": [
    "KNOWN_PRODUCT_PROPERTY_TYPE_BRAND",
    "KNOWN_PRODUCT_PROPERTY_TYPE_CATEGORY",
    "KNOWN_PRODUCT_PROPERTY_TYPE_COLLECTION",
    "KNOWN_PRODUCT_PROPERTY_TYPE_COLOR",
    "KNOWN_PRODUCT_PROPERTY_TYPE_NAME",
    "KNOWN_PRODUCT_PROPERTY_TYPE_PRICE",
    "KNOWN_PRODUCT_PROPERTY_TYPE_SIZE",
    "KNOWN_PRODUCT_PROPERTY_TYPE_TAG"
  ],
  "LegacyOrderColumn": [
    "ID",
    "NAME"
  ],
  "LegacyOrderDirection": [
    "ASC",
    "DESC"
  ],
  "LegacySegmentSource": [
    "LEGACY_SEGMENT_SOURCE_DYNAMIC",
    "LEGACY_SEGMENT_SOURCE_INTERNAL_API",
    "LEGACY_SEGMENT_SOURCE_MANUAL_UPLOAD",
    "LEGACY_SEGMENT_SOURCE_THIRD_PARTY"
  ],
  "LegacySegmentStatus": [
    "LEGACY_SEGMENT_STATUS_ACTIVE",
    "LEGACY_SEGMENT_STATUS_DELETED",
    "LEGACY_SEGMENT_STATUS_INACTIVE"
  ],
  "LinkParameterMessageChannel": [
    "LINK_PARAMETER_MESSAGE_CHANNEL_EMAIL",
    "LINK_PARAMETER_MESSAGE_CHANNEL_GLOBAL",
    "LINK_PARAMETER_MESSAGE_CHANNEL_TEXT"
  ],
  "LinkParameterMessageType": [
    "LINK_PARAMETER_MESSAGE_TYPE_CAMPAIGN",
    "LINK_PARAMETER_MESSAGE_TYPE_JOURNEY"
  ],
  "ListComposeCampaignUiFilter": [
    "LIST_COMPOSE_CAMPAIGN_UI_FILTER_DELIVERED",
    "LIST_COMPOSE_CAMPAIGN_UI_FILTER_DRAFT",
    "LIST_COMPOSE_CAMPAIGN_UI_FILTER_NONE",
    "LIST_COMPOSE_CAMPAIGN_UI_FILTER_PAUSED",
    "LIST_COMPOSE_CAMPAIGN_UI_FILTER_SCHEDULED"
  ],
  "ListCreativeExperimentsSortDirection": [
    "CREATIVE_EXPERIMENTS_SORT_DIRECTION_ASC",
    "CREATIVE_EXPERIMENTS_SORT_DIRECTION_DESC"
  ],
  "ListCreativeExperimentsSortField": [
    "CREATIVE_EXPERIMENTS_SORT_FIELD_CLICKS",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_CREATED",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_CTR",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_CVR",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_IMPRESSIONS",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_INTERNAL_ID",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_NAME",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_STATUS_LABEL",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS",
    "CREATIVE_EXPERIMENTS_SORT_FIELD_UPDATED"
  ],
  "ListCreativesAndExperimentsFilterUserView": [
    "LIST_CREATIVES_AND_EXPERIMENTS_FILTER_USER_VIEW_SUPERUSER"
  ],
  "ListCreativesAndExperimentsSortDirection": [
    "CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_ASC",
    "CREATIVES_AND_EXPERIMENTS_SORT_DIRECTION_DESC"
  ],
  "ListCreativesAndExperimentsSortField": [
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CLICKS",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_COUNTRY_CODE",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CREATED",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CTR",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_CVR",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_IMPRESSIONS",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_INTERNAL_ID",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_LANGUAGE",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_NAME",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_RULES",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_STATUS_LABEL",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_SUBSCRIBERS",
    "CREATIVES_AND_EXPERIMENTS_SORT_FIELD_UPDATED"
  ],
  "ListCreativesFilterUserView": [
    "LIST_CREATIVES_FILTER_USER_VIEW_SUPERUSER"
  ],
  "ListCreativesSortDirection": [
    "CREATIVES_SORT_DIRECTION_ASC",
    "CREATIVES_SORT_DIRECTION_DESC"
  ],
  "ListCreativesSortField": [
    "CREATIVES_SORT_FIELD_ARCHIVED",
    "CREATIVES_SORT_FIELD_CLICKS",
    "CREATIVES_SORT_FIELD_COUNTRY_CODE",
    "CREATIVES_SORT_FIELD_CREATED",
    "CREATIVES_SORT_FIELD_CTR",
    "CREATIVES_SORT_FIELD_CVR",
    "CREATIVES_SORT_FIELD_DISPLAY_RULES",
    "CREATIVES_SORT_FIELD_HAS_COUPON_SET",
    "CREATIVES_SORT_FIELD_IMPRESSIONS",
    "CREATIVES_SORT_FIELD_INTERNAL_ID",
    "CREATIVES_SORT_FIELD_LANGUAGE",
    "CREATIVES_SORT_FIELD_NAME",
    "CREATIVES_SORT_FIELD_STATUS",
    "CREATIVES_SORT_FIELD_STATUS_LABEL",
    "CREATIVES_SORT_FIELD_SUBSCRIBERS",
    "CREATIVES_SORT_FIELD_SUBTYPE",
    "CREATIVES_SORT_FIELD_TYPE",
    "CREATIVES_SORT_FIELD_UPDATED"
  ],
  "MLAttributeType": [
    "ML_ATTRIBUTE_TYPE_PURCHASE_PROPENSITY_SCORE"
  ],
  "MacroBehavior": [
    "MACRO_BEHAVIOR_BOOLEAN",
    "MACRO_BEHAVIOR_DATE",
    "MACRO_BEHAVIOR_ENUM",
    "MACRO_BEHAVIOR_IMAGE",
    "MACRO_BEHAVIOR_LINK",
    "MACRO_BEHAVIOR_NUMBER",
    "MACRO_BEHAVIOR_OBJECT",
    "MACRO_BEHAVIOR_OBJECT_LIST",
    "MACRO_BEHAVIOR_PRIMITIVE_LIST",
    "MACRO_BEHAVIOR_TEXT",
    "MACRO_BEHAVIOR_UNKNOWN_TEXT"
  ],
  "MacroCategory": [
    "COMPANY",
    "COUPON",
    "EVENT",
    "PERSONALIZATION"
  ],
  "MacroType": [
    "LINK",
    "TEXT"
  ],
  "ManualSegmentSource": [
    "MANUAL_SEGMENT_SOURCE_INTERNAL",
    "MANUAL_SEGMENT_SOURCE_THIRD_PARTY"
  ],
  "MessageDirection": [
    "MESSAGE_DIRECTION_INBOUND",
    "MESSAGE_DIRECTION_OUTBOUND"
  ],
  "MessageFeedbackGivenBy": [
    "AGENT",
    "CLIENT",
    "UNKNOWN_GIVEN_BY"
  ],
  "MessageFeedbackOutcome": [
    "NOTES",
    "NO_ACTION",
    "TEMPLATE",
    "UNKNOWN_OUTCOME"
  ],
  "MessageFeedbackSortColumn": [
    "MESSAGE_FEEDBACK_SORT_COLUMN_DATE_GIVEN",
    "MESSAGE_FEEDBACK_SORT_COLUMN_DATE_RESOLVED",
    "MESSAGE_FEEDBACK_SORT_COLUMN_UNKNOWN_COLUMN"
  ],
  "MessageFeedbackSortDirection": [
    "MESSAGE_FEEDBACK_SORT_DIRECTION_ASC",
    "MESSAGE_FEEDBACK_SORT_DIRECTION_DESC",
    "MESSAGE_FEEDBACK_SORT_UNKNOWN_DIRECTION"
  ],
  "MessageFeedbackStatusFilter": [
    "MESSAGE_FEEDBACK_STATUS_HANDLED",
    "MESSAGE_FEEDBACK_STATUS_NOT_HANDLED",
    "MESSAGE_FEEDBACK_STATUS_UNKNOWN_HANDLED"
  ],
  "MessageFeedbackType": [
    "NEGATIVE",
    "NEUTRAL",
    "POSITIVE",
    "UNKNOWN_TYPE"
  ],
  "MessageSequenceType": [
    "MESSAGE_SEQUENCE_TYPE_BROWSE_ABANDONMENT_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_FOLLOWUP_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_CART_ABANDONMENT_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_CONTACT_CARD_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_CONTROL_FOLLOWUP_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_CONTROL_WELCOME_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_FOLLOWUP_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_INITIAL_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_LEGAL_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_SESSION_ABANDONMENT_MESSAGE",
    "MESSAGE_SEQUENCE_TYPE_WELCOME_MESSAGE"
  ],
  "MessageSortField": [
    "MESSAGE_SORT_FIELD_CREATED_AT"
  ],
  "MessageStage": [
    "STAGE_ATTENTIVE_PLATFORM",
    "STAGE_CARRIER_NETWORK",
    "STAGE_SENDING_PARTNER"
  ],
  "MessageStatus": [
    "STATUS_CREATED",
    "STATUS_DELIVERED",
    "STATUS_FAILED",
    "STATUS_FATIGUED",
    "STATUS_INCOMPLETE",
    "STATUS_MITIGATED",
    "STATUS_RECEIVED",
    "STATUS_SEEN",
    "STATUS_SENT",
    "STATUS_UNDELIVERED"
  ],
  "MessageStep": [
    "STEP_ACCEPTED",
    "STEP_CANCELLED",
    "STEP_CREATED",
    "STEP_DELIVERED",
    "STEP_DOWNGRADED",
    "STEP_FAILED",
    "STEP_FATIGUED",
    "STEP_PAUSED",
    "STEP_PROBLEM",
    "STEP_QUEUED",
    "STEP_RECEIVED",
    "STEP_RESUMED",
    "STEP_SEEN",
    "STEP_SENDING",
    "STEP_SENT",
    "STEP_UNDELIVERED",
    "STEP_UNDETERMINED"
  ],
  "MessageTone": [
    "MESSAGE_TONE_CASUAL",
    "MESSAGE_TONE_FORMAL",
    "MESSAGE_TONE_POLISHED",
    "MESSAGE_TONE_SUPER_CASUAL"
  ],
  "MessageType": [
    "MESSAGE_TYPE_BROWSE_ABANDONMENT",
    "MESSAGE_TYPE_CART_ABANDONMENT",
    "MESSAGE_TYPE_CART_ABANDONMENT_FOLLOWUP_1",
    "MESSAGE_TYPE_CONTACT_CARD",
    "MESSAGE_TYPE_SESSION_ABANDONMENT",
    "MESSAGE_TYPE_WELCOME",
    "MESSAGE_TYPE_WELCOME_FOLLOWUP_1",
    "MESSAGE_TYPE_WELCOME_FOLLOWUP_2",
    "MESSAGE_TYPE_WELCOME_FOLLOWUP_3",
    "MESSAGE_TYPE_WELCOME_FOLLOWUP_4"
  ],
  "MessagingChannel": [
    "MESSAGING_CHANNEL_EMAIL",
    "MESSAGING_CHANNEL_TEXT"
  ],
  "MessagingServiceProvider": [
    "MESSAGING_SERVICE_PROVIDER_OPEN_MARKET",
    "MESSAGING_SERVICE_PROVIDER_SINCH",
    "MESSAGING_SERVICE_PROVIDER_TWILIO",
    "MESSAGING_SERVICE_PROVIDER_ZIPWHIP"
  ],
  "MessagingServiceStatus": [
    "MESSAGING_SERVICE_STATUS_ACTIVE",
    "MESSAGING_SERVICE_STATUS_INACTIVE",
    "MESSAGING_SERVICE_STATUS_TESTING"
  ],
  "MessagingTemplateType": [
    "MESSAGING_TEMPLATE_TYPE_CAMPAIGN",
    "MESSAGING_TEMPLATE_TYPE_COMPANY_EMAIL_COMMON",
    "MESSAGING_TEMPLATE_TYPE_EMAIL_PREVIEW",
    "MESSAGING_TEMPLATE_TYPE_JOINED_A_SEGMENT",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_BACK_IN_STOCK",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_BROWSE_ABANDONER",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_CART_ABANDONER",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_CHECKOUT_ABANDONER",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_POST_PURCHASE",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_WELCOME_EMAIL",
    "MESSAGING_TEMPLATE_TYPE_JOURNEYS_WINBACK",
    "MESSAGING_TEMPLATE_TYPE_LOW_INVENTORY",
    "MESSAGING_TEMPLATE_TYPE_PRICE_DROP",
    "MESSAGING_TEMPLATE_TYPE_ROW"
  ],
  "MetricAggregationType": [
    "METRIC_AGGREGATION_TYPE_AVERAGE",
    "METRIC_AGGREGATION_TYPE_COUNT",
    "METRIC_AGGREGATION_TYPE_COUNT_DISTINCT",
    "METRIC_AGGREGATION_TYPE_POINT_IN_TIME_COUNT",
    "METRIC_AGGREGATION_TYPE_POINT_IN_TIME_SUM",
    "METRIC_AGGREGATION_TYPE_SUM"
  ],
  "MetricConnotation": [
    "METRIC_CONNOTATION_NEGATIVE",
    "METRIC_CONNOTATION_NEUTRAL",
    "METRIC_CONNOTATION_POSITIVE"
  ],
  "MetricDataErrorType": [
    "METRIC_DATA_ERROR_TYPE_DIVIDE_BY_ZERO",
    "METRIC_DATA_ERROR_TYPE_NO_DATA"
  ],
  "MetricDataType": [
    "METRIC_DATA_TYPE_CURRENCY",
    "METRIC_DATA_TYPE_NUMBER",
    "METRIC_DATA_TYPE_PERCENT"
  ],
  "MetricsOrderByDirection": [
    "METRICS_ORDER_BY_DIRECTION_ASC",
    "METRICS_ORDER_BY_DIRECTION_DESC"
  ],
  "MfaOneTimePasscodeDeliveryMethod": [
    "MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_EMAIL",
    "MFA_ONE_TIME_PASSCODE_DELIVERY_METHOD_SMS"
  ],
  "MilestoneKey": [
    "MILESTONE_KEY_ADDED_USER_ACCOUNTS",
    "MILESTONE_KEY_COMPLETED_BRAND_STYLES",
    "MILESTONE_KEY_COMPLETED_COMPANY_FORM",
    "MILESTONE_KEY_COMPLETED_INTEGRATION_SURVEY",
    "MILESTONE_KEY_COMPLETED_ONBOARDING",
    "MILESTONE_KEY_COMPLETED_RTL_CREATIVE",
    "MILESTONE_KEY_COMPLETED_WELCOME_JOURNEY",
    "MILESTONE_KEY_DEFAULT_OFFER_REVIEWED",
    "MILESTONE_KEY_ECOMMERCE_PLATFORM_COMPLETED",
    "MILESTONE_KEY_EMAIL_CTA_FIRST_CLICKED",
    "MILESTONE_KEY_EMAIL_FIRST_SENT",
    "MILESTONE_KEY_ESP_INTEGRATION_COMPLETED",
    "MILESTONE_KEY_IMPORTED_FROM_SALESFORCE",
    "MILESTONE_KEY_INSTALLED_TAG",
    "MILESTONE_KEY_SALESFORCE_CONVERTED_TO_PAYING",
    "MILESTONE_KEY_SALESFORCE_GO_LIVE"
  ],
  "MobileWalletSortingField": [
    "MOBILE_WALLET_SORTING_FIELD_ID",
    "MOBILE_WALLET_SORTING_FIELD_UPDATED"
  ],
  "NextBestActionCategory": [
    "NBA_CATEGORY_DRIVE_ROI",
    "NBA_CATEGORY_GROW_YOUR_LIST",
    "NBA_CATEGORY_INTEGRATIONS",
    "NBA_CATEGORY_MAXIMIZE_REVENUE"
  ],
  "NextBestActionDisplayType": [
    "NBA_DISPLAY_TYPE_SIMPLE_PROMPT",
    "NBA_DISPLAY_TYPE_VIDEO_PROMPT"
  ],
  "NotificationType": [
    "NOTIFICATION_TYPE_ALERTS",
    "NOTIFICATION_TYPE_ALL",
    "NOTIFICATION_TYPE_FEATURED",
    "NOTIFICATION_TYPE_MARKETING",
    "NOTIFICATION_TYPE_NEXT_BEST_ACTION",
    "NOTIFICATION_TYPE_PERFORMANCE",
    "NOTIFICATION_TYPE_PRODUCT_CONTENT"
  ],
  "NumericFilterOperator": [
    "NUMERIC_FILTER_OPERATOR_IN",
    "NUMERIC_FILTER_OPERATOR_NOT_IN"
  ],
  "OfferDiscountType": [
    "DISCOUNT_TYPE_CODE",
    "DISCOUNT_TYPE_DOLLAR_AMOUNT",
    "DISCOUNT_TYPE_PERCENTAGE"
  ],
  "OnboardingSurveySelectionType": [
    "ONBOARDING_SURVEY_SELECTION_TYPE_ECOMMERCE_PLATFORM",
    "ONBOARDING_SURVEY_SELECTION_TYPE_EMAIL_PROVIDER",
    "ONBOARDING_SURVEY_SELECTION_TYPE_OTHER_SERVICES"
  ],
  "OpenAIModel": [
    "OPENAI_MODEL_GPT_3_5_TURBO",
    "OPENAI_MODEL_GPT_4",
    "OPENAI_MODEL_GPT_4_300_TEST",
    "OPENAI_MODEL_GPT_4_O",
    "OPENAI_MODEL_GPT_4_TURBO",
    "OPEN_AI_MODEL_GPT_4_0_MINI"
  ],
  "OperationMode": [
    "OPERATION_MODE_CHECKPOINT",
    "OPERATION_MODE_NONE",
    "OPERATION_MODE_SAVEPOINT"
  ],
  "OperationResult": [
    "OPERATION_RESULT_FAILURE",
    "OPERATION_RESULT_SUCCESS"
  ],
  "OptOutSource": [
    "OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL",
    "OPT_OUT_SOURCE_CCPA",
    "OPT_OUT_SOURCE_CLIENT",
    "OPT_OUT_SOURCE_EMAIL",
    "OPT_OUT_SOURCE_KEYWORD",
    "OPT_OUT_SOURCE_LITIGATOR",
    "OPT_OUT_SOURCE_SCRIPT"
  ],
  "OrchestrationType": [
    "ORCHESTRATION_TYPE_CAMPAIGN"
  ],
  "OrderColumn": [
    "ID",
    "NAME"
  ],
  "OrderDirection": [
    "ASC",
    "DESC"
  ],
  "PartnershipTier": [
    "ELITE",
    "NONE",
    "PREMIER"
  ],
  "PassedTimezonesStrategy": [
    "PASSED_TIMEZONES_STRATEGY_DO_NOT_SEND",
    "PASSED_TIMEZONES_STRATEGY_SEND_NOW",
    "PASSED_TIMEZONES_STRATEGY_SEND_ONE_DAY_LATER"
  ],
  "PaymentCompanyStateChangeStatus": [
    "PAYMENT_COMPANY_STATE_CHANGE_STATUS_FAIL",
    "PAYMENT_COMPANY_STATE_CHANGE_STATUS_SUCCESS"
  ],
  "PaymentCompanyStatus": [
    "ACTIVE",
    "INACTIVE",
    "NON_EXISTENT"
  ],
  "PaymentOfferStatus": [
    "PAYMENT_OFFER_STATUS_ACTIVE",
    "PAYMENT_OFFER_STATUS_DELETED",
    "PAYMENT_OFFER_STATUS_DRAFT",
    "PAYMENT_OFFER_STATUS_EXPIRED"
  ],
  "PaymentOfferType": [
    "DYNAMIC",
    "STATIC"
  ],
  "PhoneApplicationStatus": [
    "PHONE_APPLICATION_STATUS_APPLIED",
    "PHONE_APPLICATION_STATUS_APPROVED",
    "PHONE_APPLICATION_STATUS_CANCELLED",
    "PHONE_APPLICATION_STATUS_PENDING_CARRIER_APPROVAL",
    "PHONE_APPLICATION_STATUS_PENDING_MOCKUP",
    "PHONE_APPLICATION_STATUS_UNASSIGNED"
  ],
  "PhoneNumberCompanyAssignmentStatus": [
    "PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_ASSIGNED",
    "PHONE_NUMBER_COMPANY_ASSIGNMENT_STATUS_NONE"
  ],
  "PhoneNumberStatus": [
    "PHONE_NUMBER_STATUS_ACTIVE",
    "PHONE_NUMBER_STATUS_INACTIVE"
  ],
  "PhoneNumberType": [
    "PHONE_NUMBER_TYPE_LONG_CODE",
    "PHONE_NUMBER_TYPE_SHORT_CODE",
    "PHONE_NUMBER_TYPE_TEN_DLC",
    "PHONE_NUMBER_TYPE_TOLL_FREE"
  ],
  "PhoneNumberUseCase": [
    "PHONE_NUMBER_USE_CASE_ATTENTIVE",
    "PHONE_NUMBER_USE_CASE_CLIENT",
    "PHONE_NUMBER_USE_CASE_TEST"
  ],
  "Placement": [
    "BRANDKIT_PLACEMENT_ADDITIONAL",
    "BRANDKIT_PLACEMENT_DEFAULT",
    "BRANDKIT_PLACEMENT_SECONDARY"
  ],
  "PollSortingField": [
    "POLL_SORTING_FIELD_NAME",
    "POLL_SORTING_FIELD_UPDATED"
  ],
  "PollState": [
    "CLOSED",
    "OFF",
    "OPEN"
  ],
  "PollStatus": [
    "POLL_STATUS_CANCELLED",
    "POLL_STATUS_COMPLETE",
    "POLL_STATUS_DELETED",
    "POLL_STATUS_DRAFT",
    "POLL_STATUS_FAILED",
    "POLL_STATUS_IN_PROGRESS"
  ],
  "PreferenceType": [
    "PREFERENCE_TYPE_ORDERING",
    "PREFERENCE_TYPE_VISIBILITY"
  ],
  "PreferredMfaMethod": [
    "PREFERRED_MFA_METHOD_EMAIL",
    "PREFERRED_MFA_METHOD_SMS"
  ],
  "PriceComparator": [
    "PRICE_COMPARATOR_GREATER_THAN",
    "PRICE_COMPARATOR_LESS_THAN"
  ],
  "PrivacyRequestSortColumn": [
    "PRIVACY_REQUEST_SORT_COLUMN_PROCESSING_DATETIME",
    "PRIVACY_REQUEST_SORT_COLUMN_REQUEST_DATETIME"
  ],
  "PrivacyRequestStatus": [
    "PRIVACY_REQUEST_STATUS_ALL",
    "PRIVACY_REQUEST_STATUS_PROCESSED",
    "PRIVACY_REQUEST_STATUS_UNPROCESSED"
  ],
  "PrivacyRequestType": [
    "ACCESS",
    "DELETE"
  ],
  "ProductAvailability": [
    "PRODUCT_AVAILABILITY_AVAILABLE_FOR_ORDER",
    "PRODUCT_AVAILABILITY_DISCONTINUED",
    "PRODUCT_AVAILABILITY_IN_STOCK",
    "PRODUCT_AVAILABILITY_OUT_OF_STOCK",
    "PRODUCT_AVAILABILITY_PENDING",
    "PRODUCT_AVAILABILITY_PREORDER",
    "PRODUCT_AVAILABILITY_REMOVED"
  ],
  "ProductRecommendationSource": [
    "RECOMMENDATION_SOURCE_COMPANY_BESTSELLERS_JOB",
    "RECOMMENDATION_SOURCE_LAST_PURCHASED_CATEGORY_BESTSELLER"
  ],
  "ProductVariantPriceType": [
    "VARIANT_PRICE_TYPE_REGULAR",
    "VARIANT_PRICE_TYPE_SALE"
  ],
  "ProfileVendor": [
    "PROFILE_VENDOR_ATTENTIVE",
    "PROFILE_VENDOR_BAZAAR_VOICE",
    "PROFILE_VENDOR_COMPANY",
    "PROFILE_VENDOR_KLAVIYO",
    "PROFILE_VENDOR_MPARTICLE",
    "PROFILE_VENDOR_PUNCHH",
    "PROFILE_VENDOR_RECHARGE",
    "PROFILE_VENDOR_SEGMENT",
    "PROFILE_VENDOR_SHOPIFY",
    "PROFILE_VENDOR_SMILE_IO",
    "PROFILE_VENDOR_YOTPO"
  ],
  "ProgramCadenceType": [
    "PROGRAM_CADENCE_TYPE_FIXED_PERIOD"
  ],
  "ProgramCalendarPeriod": [
    "PROGRAM_CALENDAR_PERIOD_DAY",
    "PROGRAM_CALENDAR_PERIOD_MONTH",
    "PROGRAM_CALENDAR_PERIOD_WEEK"
  ],
  "ProgramStatus": [
    "PROGRAM_STATUS_ACTIVE",
    "PROGRAM_STATUS_INACTIVE"
  ],
  "PromptRefinementOption": [
    "PROMPT_REFINEMENT_OPTION_ADD_EMOJIS",
    "PROMPT_REFINEMENT_OPTION_ADD_URGENCY",
    "PROMPT_REFINEMENT_OPTION_MAKE_CASUAL",
    "PROMPT_REFINEMENT_OPTION_MAKE_FUNNY",
    "PROMPT_REFINEMENT_OPTION_MAKE_SHORTER",
    "PROMPT_REFINEMENT_OPTION_USE_BRAND_VOICE"
  ],
  "ProtoEventJoinerType": [
    "PROTO_EVENT_JOINER_TYPE_ENRICHER_ANALYTICS"
  ],
  "Provider": [
    "PROVIDER_BANDWIDTH",
    "PROVIDER_GENERIC",
    "PROVIDER_IMI_MOBILE",
    "PROVIDER_MITTO",
    "PROVIDER_OPEN_MARKET",
    "PROVIDER_SINCH",
    "PROVIDER_TWILIO",
    "PROVIDER_VIBES",
    "PROVIDER_ZIPWHIP"
  ],
  "ProviderMigrationStatus": [
    "PROVIDER_MIGRATION_STATUS_COMPLETED",
    "PROVIDER_MIGRATION_STATUS_IN_PROGRESS",
    "PROVIDER_MIGRATION_STATUS_NOT_STARTED"
  ],
  "PublicEndpoint": [
    "PUBLIC_ENDPOINT_ADD_CUSTOM_IDENTIFIER",
    "PUBLIC_ENDPOINT_ADD_PRIVACY_DELETE_REQUEST",
    "PUBLIC_ENDPOINT_ADD_TO_CART",
    "PUBLIC_ENDPOINT_AUTOGENERATE_COUPONS",
    "PUBLIC_ENDPOINT_BULK_SEND_TEXT",
    "PUBLIC_ENDPOINT_CREATE_WEBHOOK",
    "PUBLIC_ENDPOINT_CUSTOM_ATTRIBUTES",
    "PUBLIC_ENDPOINT_CUSTOM_EVENTS",
    "PUBLIC_ENDPOINT_DELETE_WEBHOOK",
    "PUBLIC_ENDPOINT_GET_SUBSCRIBER_ELIGIBILITY",
    "PUBLIC_ENDPOINT_LIST_WEBHOOKS",
    "PUBLIC_ENDPOINT_ME",
    "PUBLIC_ENDPOINT_PRODUCT_VIEW",
    "PUBLIC_ENDPOINT_PURCHASE",
    "PUBLIC_ENDPOINT_SEND_CAMPAIGN_TEXT",
    "PUBLIC_ENDPOINT_SEND_TEXT",
    "PUBLIC_ENDPOINT_SUBSCRIBE_USER",
    "PUBLIC_ENDPOINT_UNSUBSCRIBE_USER",
    "PUBLIC_ENDPOINT_UPDATE_WEBHOOK",
    "PUBLIC_ENDPOINT_UPLOAD_PRODUCT_CATALOG",
    "PUBLIC_ENDPOINT_UPSERT_MESSAGE_TEXT",
    "PUBLIC_ENDPOINT_VIEW_PRODUCT_CATALOG_INGESTION",
    "PUBLIC_ENDPOINT_VIEW_RECENT_CATALOG_UPLOADS"
  ],
  "RateLimitCountry": [
    "RATE_LIMIT_COUNTRY_AU",
    "RATE_LIMIT_COUNTRY_CA",
    "RATE_LIMIT_COUNTRY_UK",
    "RATE_LIMIT_COUNTRY_US"
  ],
  "RecommendationAttachmentType": [
    "RECOMMENDATION_ATTACHMENT_TYPE_COMPANY_BESTSELLER",
    "RECOMMENDATION_ATTACHMENT_TYPE_PERSONALIZED"
  ],
  "RedeemCouponError": [
    "REDEEM_COUPON_ERROR_ALREADY_REDEEMED",
    "REDEEM_COUPON_ERROR_API",
    "REDEEM_COUPON_ERROR_EXPIRED",
    "REDEEM_COUPON_ERROR_INVALID"
  ],
  "RegionDesignation": [
    "REGION_DESIGNATION_DEFAULT",
    "REGION_DESIGNATION_OTHER"
  ],
  "ReplaySourceType": [
    "SOURCE_TYPE_EVENT",
    "SOURCE_TYPE_GENERIC",
    "SOURCE_TYPE_ISTIO_LOGS"
  ],
  "ReportChart": [
    "REPORT_CHART_EXPANSION_REVENUE",
    "REPORT_CHART_INCREMENTAL_REVENUE",
    "REPORT_CHART_LINE_CHART",
    "REPORT_CHART_SUPPRESSED_SENDS"
  ],
  "ReportDeliveryFrequency": [
    "REPORT_DELIVERY_FREQUENCY_DAILY",
    "REPORT_DELIVERY_FREQUENCY_EMPTY",
    "REPORT_DELIVERY_FREQUENCY_MONTHLY",
    "REPORT_DELIVERY_FREQUENCY_QUARTERLY",
    "REPORT_DELIVERY_FREQUENCY_WEEKLY"
  ],
  "ReportType": [
    "REPORT_TYPE_CUSTOM",
    "REPORT_TYPE_LEGACY",
    "REPORT_TYPE_STANDARD"
  ],
  "ReportsSortColumn": [
    "REPORTS_SORT_COLUMN_NAME",
    "REPORTS_SORT_COLUMN_NEXT_DELIVERY_DATE",
    "REPORTS_SORT_COLUMN_TYPE"
  ],
  "ReportsSortDirection": [
    "REPORTS_SORT_DIRECTION_ASC",
    "REPORTS_SORT_DIRECTION_DESC"
  ],
  "ReservedKeywordType": [
    "RESERVED_KEYWORD_TYPE_COMPANY_CODE",
    "RESERVED_KEYWORD_TYPE_INCOMING_TEXT",
    "RESERVED_KEYWORD_TYPE_SUBSCRIBER_OPT_OUT",
    "RESERVED_KEYWORD_TYPE_TCPA_COMPLIANCE",
    "RESERVED_KEYWORD_TYPE_TWO_WAY_MESSAGE"
  ],
  "ReservedPropertyType": [
    "RESERVED_PROPERTY_TYPE_COUNTRY",
    "RESERVED_PROPERTY_TYPE_FIRST_NAME",
    "RESERVED_PROPERTY_TYPE_FULL_NAME",
    "RESERVED_PROPERTY_TYPE_LAST_NAME",
    "RESERVED_PROPERTY_TYPE_LOCALE"
  ],
  "RevisePaymentsOfferStatus": [
    "REVISE_OFFER_STATUS_FAIL_KEYWORD_NOT_ACCEPTABLE",
    "REVISE_OFFER_STATUS_FAIL_RESERVED_KEYWORD",
    "REVISE_OFFER_STATUS_OFFER_IN_USE",
    "REVISE_OFFER_STATUS_SUCCESS"
  ],
  "RevisionStepValue": [
    "REVISION_STEP_VALUE_CREATE_SUPPORT_TICKET",
    "REVISION_STEP_VALUE_FORWARD_TO_CONCIERGE",
    "REVISION_STEP_VALUE_INCOMING_TEXT",
    "REVISION_STEP_VALUE_KEYWORD_RESPONSE",
    "REVISION_STEP_VALUE_OPEN_RESPONSE",
    "REVISION_STEP_VALUE_SAVE_USER_ATTRIBUTE",
    "REVISION_STEP_VALUE_SEND_CONVERSATION_MESSAGE",
    "REVISION_STEP_VALUE_SEND_EMAIL",
    "REVISION_STEP_VALUE_SEND_LEGAL_WELCOME",
    "REVISION_STEP_VALUE_SEND_MESSAGE",
    "REVISION_STEP_VALUE_TRIGGER",
    "REVISION_STEP_VALUE_TWO_WAY_MESSAGE",
    "REVISION_STEP_VALUE_VARIANT"
  ],
  "Role": [
    "ROLE_ADMIN_ACCESS",
    "ROLE_ATTENTIVE_ACCOUNT",
    "ROLE_CLIENT_ACCOUNT",
    "ROLE_CLIENT_ADMIN_ACCOUNT",
    "ROLE_CLIENT_LIMITED_ACCOUNT",
    "ROLE_CLIENT_MANAGED_ACCOUNT",
    "ROLE_CLIENT_MANAGED_ADMIN_ACCOUNT",
    "ROLE_CLIENT_MEMBER_ACCOUNT",
    "ROLE_CLIENT_STRATEGY",
    "ROLE_CONCIERGE",
    "ROLE_CONCIERGE_OPERATIONS",
    "ROLE_CS_TAM",
    "ROLE_E2E_TEST_USER",
    "ROLE_ENGINEERING",
    "ROLE_ENGINEERING_IDENTITY",
    "ROLE_ENGINEERING_SALES",
    "ROLE_ENGINEERING_SOLUTIONS",
    "ROLE_IT_SECURITY",
    "ROLE_MESSAGING_OPS",
    "ROLE_PRODUCT_MANAGEMENT",
    "ROLE_READONLY_CONCIERGE",
    "ROLE_SUBSCRIBER_AUDITOR",
    "ROLE_SUPER_USER"
  ],
  "SceneConstructionOptionType": [
    "SCENE_CONSTRUCTION_OPTION_TYPE_MULTI_SELECT",
    "SCENE_CONSTRUCTION_OPTION_TYPE_SINGLE_SELECT"
  ],
  "SceneCreationOptionType": [
    "SCENE_CREATION_OPTION_TYPE_MULTI_SELECT",
    "SCENE_CREATION_OPTION_TYPE_SINGLE_SELECT"
  ],
  "ScheduleType": [
    "SCHEDULE_TYPE_FIXED_DATE_LIST",
    "SCHEDULE_TYPE_RECURRING"
  ],
  "ScheduleWarnings": [
    "SCHEDULE_WARNING_UNSET",
    "SCHEDULE_WARNING_WARMUP_NOT_FINISHED",
    "SCHEDULE_WARNING_WARMUP_NOT_SCHEDULED",
    "SCHEDULE_WARNING_WARMUP_PARTIALLY_CANCELLED"
  ],
  "Scope": [
    "SCOPE_ALL_ACCOUNTS",
    "SCOPE_ALL_CONCIERGE_SETTINGS",
    "SCOPE_ALL_COUPONS",
    "SCOPE_ALL_CUSTOM_ATTRIBUTES",
    "SCOPE_ALL_CUSTOM_EVENTS",
    "SCOPE_ALL_ECOMMERCE_EVENTS",
    "SCOPE_ALL_IDENTITY",
    "SCOPE_ALL_MESSAGES",
    "SCOPE_ALL_OFFERS",
    "SCOPE_ALL_POLLS",
    "SCOPE_ALL_PRIVACY_REQUESTS",
    "SCOPE_ALL_PRODUCT_CATALOGS",
    "SCOPE_ALL_PROVISION",
    "SCOPE_ALL_SCIM",
    "SCOPE_ALL_SUBSCRIBER_EVENTS",
    "SCOPE_ALL_WEBHOOKS",
    "SCOPE_AUDIT_SUBSCRIPTIONS",
    "SCOPE_VIEW_POLLS",
    "SCOPE_VIEW_PRIVACY_REQUESTS",
    "SCOPE_VIEW_PRODUCT_CATALOGS",
    "SCOPE_VIEW_PROVISION",
    "SCOPE_VIEW_SCIM",
    "SCOPE_VIEW_SUBSCRIBER_EVENTS"
  ],
  "SearchBooleanComparator": [
    "SEARCH_BOOLEAN_COMPARATOR_EQUALS"
  ],
  "SearchDateComponentComparator": [
    "SEARCH_DATE_COMPONENT_COMPARATOR_MONTH_OF"
  ],
  "SearchDistanceComparator": [
    "SEARCH_DISTANCE_COMPARATOR_WITHIN"
  ],
  "SearchDistanceUnit": [
    "SEARCH_DISTANCE_UNIT_KILOMETERS",
    "SEARCH_DISTANCE_UNIT_MILES"
  ],
  "SearchFrequencyComparator": [
    "SEARCH_FREQUENCY_COMPARATOR_BETWEEN",
    "SEARCH_FREQUENCY_COMPARATOR_EQUALS",
    "SEARCH_FREQUENCY_COMPARATOR_LESS_THAN",
    "SEARCH_FREQUENCY_COMPARATOR_LESS_THAN_EQUALS",
    "SEARCH_FREQUENCY_COMPARATOR_MORE_THAN",
    "SEARCH_FREQUENCY_COMPARATOR_MORE_THAN_EQUALS"
  ],
  "SearchGroupOperation": [
    "SEARCH_GROUP_OPERATION_ALL",
    "SEARCH_GROUP_OPERATION_ANY",
    "SEARCH_GROUP_OPERATION_NOT"
  ],
  "SearchNumericComparator": [
    "SEARCH_NUMERIC_COMPARATOR_BETWEEN",
    "SEARCH_NUMERIC_COMPARATOR_EQUALS",
    "SEARCH_NUMERIC_COMPARATOR_IN",
    "SEARCH_NUMERIC_COMPARATOR_LESS_THAN",
    "SEARCH_NUMERIC_COMPARATOR_LESS_THAN_EQUALS",
    "SEARCH_NUMERIC_COMPARATOR_MORE_THAN",
    "SEARCH_NUMERIC_COMPARATOR_MORE_THAN_EQUALS"
  ],
  "SearchOperator": [
    "SEARCH_OPERATOR_ALL",
    "SEARCH_OPERATOR_ANY"
  ],
  "SearchRelativeTimeComparator": [
    "SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST",
    "SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_LAST_IGNORE_YEAR",
    "SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT",
    "SEARCH_RELATIVE_TIME_COMPARATOR_BETWEEN_THE_NEXT_IGNORE_YEAR",
    "SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO",
    "SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_AGO_IGNORE_YEAR",
    "SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL",
    "SEARCH_RELATIVE_TIME_COMPARATOR_DAYS_UNTIL_IGNORE_YEAR",
    "SEARCH_RELATIVE_TIME_COMPARATOR_TODAY",
    "SEARCH_RELATIVE_TIME_COMPARATOR_TODAY_IGNORE_YEAR"
  ],
  "SearchStringComparator": [
    "SEARCH_STRING_COMPARATOR_EQUALS",
    "SEARCH_STRING_COMPARATOR_IN",
    "SEARCH_STRING_COMPARATOR_LIKE"
  ],
  "SearchTimeComparator": [
    "SEARCH_TIME_COMPARATOR_AFTER",
    "SEARCH_TIME_COMPARATOR_BEFORE",
    "SEARCH_TIME_COMPARATOR_BETWEEN",
    "SEARCH_TIME_COMPARATOR_BETWEEN_IGNORE_YEAR",
    "SEARCH_TIME_COMPARATOR_ON",
    "SEARCH_TIME_COMPARATOR_ON_IGNORE_YEAR",
    "SEARCH_TIME_COMPARATOR_OVER_ALL_TIME"
  ],
  "SearchTimeUnit": [
    "SEARCH_TIME_UNIT_DAY",
    "SEARCH_TIME_UNIT_HOURS",
    "SEARCH_TIME_UNIT_MONTH"
  ],
  "SegmentAutomationType": [
    "SEGMENT_AUTOMATION_TYPE_BLENDED",
    "SEGMENT_AUTOMATION_TYPE_EXCLUSION",
    "SEGMENT_AUTOMATION_TYPE_REVENUE_BOOSTING",
    "SEGMENT_AUTOMATION_TYPE_REVENUE_MAXIMIZING",
    "SEGMENT_AUTOMATION_TYPE_ROI_BOOSTING",
    "SEGMENT_AUTOMATION_TYPE_ROI_MAXIMIZING"
  ],
  "SegmentSource": [
    "SEGMENT_SOURCE_DYNAMIC",
    "SEGMENT_SOURCE_INTERNAL_API",
    "SEGMENT_SOURCE_MANUAL_UPLOAD",
    "SEGMENT_SOURCE_THIRD_PARTY"
  ],
  "SegmentStatus": [
    "SEGMENT_STATUS_ACTIVE",
    "SEGMENT_STATUS_DELETED",
    "SEGMENT_STATUS_INACTIVE"
  ],
  "SegmentTag": [
    "TAG_ALL_SUBSCRIBERS",
    "TAG_BLENDED",
    "TAG_CONCIERGE_FOLLOWUP",
    "TAG_EXCLUSION",
    "TAG_EXIT_RULE",
    "TAG_JOURNEY_TEMPLATE",
    "TAG_MANUAL_SEGMENT_CREATED_IN_SS",
    "TAG_ML_ATTRIBUTE",
    "TAG_RETARGETING",
    "TAG_REVENUE_BOOSTING",
    "TAG_REVENUE_MAXIMIZING",
    "TAG_ROI_BOOSTING",
    "TAG_ROI_MAXIMIZING",
    "TAG_WARMUPS"
  ],
  "SegmentType": [
    "AUDIENCES_AI_EXCLUSION",
    "AUDIENCES_AI_INCLUSION"
  ],
  "SftpConnectionStatus": [
    "SFTP_CONNECTION_STATUS_CONNECTION_ERROR",
    "SFTP_CONNECTION_STATUS_MISSING_FILE",
    "SFTP_CONNECTION_STATUS_MISSING_PATH",
    "SFTP_CONNECTION_STATUS_SUCCESS"
  ],
  "SftpReportFeature": [
    "SFTP_REPORT_FEATURE_CONVERSIONS",
    "SFTP_REPORT_FEATURE_GENERAL_DATAFEED",
    "SFTP_REPORT_FEATURE_OPT_OUT"
  ],
  "ShippingProvider": [
    "SHIPPING_PROVIDER_DHL",
    "SHIPPING_PROVIDER_FEDEX",
    "SHIPPING_PROVIDER_UPS",
    "SHIPPING_PROVIDER_USPS"
  ],
  "ShortCodeLeaseOwner": [
    "SHORT_CODE_LEASE_OWNER_AGGREGATOR",
    "SHORT_CODE_LEASE_OWNER_ATTENTIVE",
    "SHORT_CODE_LEASE_OWNER_CLIENT"
  ],
  "SortDirection": [
    "SORT_DIRECTION_ASC",
    "SORT_DIRECTION_DESC"
  ],
  "SourceType": [
    "SOURCE_TYPE_COMPANY_SETTINGS",
    "SOURCE_TYPE_CONVERSATION",
    "SOURCE_TYPE_FEEDBACK"
  ],
  "StandardErrorStatus": [
    "STANDARD_ERROR_STATUS_ABORTED",
    "STANDARD_ERROR_STATUS_ALREADY_EXISTS",
    "STANDARD_ERROR_STATUS_CANCELLED",
    "STANDARD_ERROR_STATUS_DATA_LOSS",
    "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED",
    "STANDARD_ERROR_STATUS_FAILED_PRECONDITION",
    "STANDARD_ERROR_STATUS_INTERNAL",
    "STANDARD_ERROR_STATUS_INVALID_ARGUMENT",
    "STANDARD_ERROR_STATUS_NOT_FOUND",
    "STANDARD_ERROR_STATUS_NOT_SET",
    "STANDARD_ERROR_STATUS_OUT_OF_RANGE",
    "STANDARD_ERROR_STATUS_PERMISSION_DENIED",
    "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED",
    "STANDARD_ERROR_STATUS_UNAUTHENTICATED",
    "STANDARD_ERROR_STATUS_UNAVAILABLE",
    "STANDARD_ERROR_STATUS_UNIMPLEMENTED"
  ],
  "StringComparator": [
    "STRING_COMPARATOR_EQUALS",
    "STRING_COMPARATOR_IN",
    "STRING_COMPARATOR_LIKE"
  ],
  "StringFilterOperator": [
    "STRING_FILTER_OPERATOR_IN",
    "STRING_FILTER_OPERATOR_NOT_IN"
  ],
  "SubscriberIdentifier": [
    "ID",
    "PHONE"
  ],
  "SubscriberUploadClientIdType": [
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID",
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID",
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID",
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID",
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID",
    "SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID"
  ],
  "SubscriberUploadDestinationType": [
    "DESTINATION_TYPE_EMAIL",
    "DESTINATION_TYPE_PHONE"
  ],
  "SubscriberUploadJobStatus": [
    "STATUS_ABANDONED",
    "STATUS_COMPLETE",
    "STATUS_INVALID",
    "STATUS_IN_PROGRESS",
    "STATUS_PROCESS",
    "STATUS_UPLOADED"
  ],
  "SubscriberUploadJobType": [
    "JOB_TYPE_ADD_SUBSCRIBERS",
    "JOB_TYPE_SUBSCRIBER_TAG_ONLY",
    "JOB_TYPE_SUPPRESS_SUBSCRIPTIONS",
    "JOB_TYPE_UNSUPPRESS_SUBSCRIPTIONS"
  ],
  "SubscriberUploadSubscriptionFlow": [
    "SUBSCRIPTION_FLOW_DOUBLE_OPT_IN",
    "SUBSCRIPTION_FLOW_SILENT_OPT_IN",
    "SUBSCRIPTION_FLOW_SINGLE_OPT_IN"
  ],
  "SubscriberUploadSubscriptionType": [
    "SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED",
    "SUBSCRIPTION_TYPE_EMAIL_MARKETING",
    "SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL",
    "SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED",
    "SUBSCRIPTION_TYPE_TEXT_MARKETING",
    "SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL"
  ],
  "SubscriberUploadUpdateStrategy": [
    "SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE",
    "SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES",
    "SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING"
  ],
  "SubscriptionChannel": [
    "CHANNEL_EMAIL",
    "CHANNEL_TEXT"
  ],
  "SubscriptionOptOutSource": [
    "OPT_OUT_SOURCE_ATTENTIVE_SUPPORT_EMAIL",
    "OPT_OUT_SOURCE_CCPA",
    "OPT_OUT_SOURCE_CLIENT",
    "OPT_OUT_SOURCE_CRM_UI_UNSUBSCRIBE_BUTTON",
    "OPT_OUT_SOURCE_EMAIL",
    "OPT_OUT_SOURCE_KEYWORD",
    "OPT_OUT_SOURCE_LITIGATOR",
    "OPT_OUT_SOURCE_SCRIPT"
  ],
  "SubscriptionType": [
    "TYPE_CHECKOUT_ABANDONED",
    "TYPE_MARKETING",
    "TYPE_TRANSACTIONAL"
  ],
  "SuppressionReason": [
    "SUPPRESSION_REASON_HIGH_BLOCK_RATE",
    "SUPPRESSION_REASON_HIGH_BOUNCE_RATE",
    "SUPPRESSION_REASON_INVALID_ADDRESS",
    "SUPPRESSION_REASON_MANUAL",
    "SUPPRESSION_REASON_ROLE_ACCOUNTS",
    "SUPPRESSION_REASON_SPAM_REPORTER",
    "SUPPRESSION_REASON_TEMPORARY_ADDRESS"
  ],
  "SweepstakesSelectorType": [
    "SWEEPSTAKES_SELECTOR_TYPE_FIRST",
    "SWEEPSTAKES_SELECTOR_TYPE_LAST",
    "SWEEPSTAKES_SELECTOR_TYPE_MOST_VOTES",
    "SWEEPSTAKES_SELECTOR_TYPE_RANDOM"
  ],
  "SweepstakesSubscriberType": [
    "SWEEPSTAKES_SUBSCRIBER_TYPE_ANY",
    "SWEEPSTAKES_SUBSCRIBER_TYPE_MARKETING"
  ],
  "SyncRunStatus": [
    "SYNC_RUN_STATUS_COMPLETED_MIXED_SUCCESS",
    "SYNC_RUN_STATUS_COMPLETED_SUCCESSFULLY",
    "SYNC_RUN_STATUS_FAILED",
    "SYNC_RUN_STATUS_RUNNING",
    "SYNC_RUN_STATUS_SCHEDULED"
  ],
  "SyncStatus": [
    "SYNC_STATUS_ACTIVE",
    "SYNC_STATUS_INACTIVE"
  ],
  "SyncTrigger": [
    "SYNC_TRIGGER_MANUAL",
    "SYNC_TRIGGER_SCHEDULED"
  ],
  "TaggableEntityType": [
    "TAGGABLE_ENTITY_TYPE_CAMPAIGN_MESSAGE_GROUP"
  ],
  "TargetCreativeStatus": [
    "TARGET_CREATIVE_STATUS_ACTIVE",
    "TARGET_CREATIVE_STATUS_INACTIVE"
  ],
  "TemplateFieldValidationType": [
    "TEMPLATE_FIELDS_VALIDATION_TYPE_SHORTCUT"
  ],
  "TemplateFilter": [
    "TEMPLATE_FILTER_COMPANY",
    "TEMPLATE_FILTER_GLOBAL"
  ],
  "TemplateRequestFilter": [
    "TEMPLATE_REQUEST_FILTER_REQUESTED",
    "TEMPLATE_REQUEST_FILTER_REVIEWED"
  ],
  "TemplateRequestStatus": [
    "TEMPLATE_REQUEST_STATUS_APPROVED",
    "TEMPLATE_REQUEST_STATUS_DENIED",
    "TEMPLATE_REQUEST_STATUS_READ",
    "TEMPLATE_REQUEST_STATUS_UNREAD"
  ],
  "TemplateRole": [
    "TEMPLATE_ROLE_SYSTEM",
    "TEMPLATE_ROLE_USER"
  ],
  "TemplatingSyntaxType": [
    "TEMPLATING_SYNTAX_TYPE_LIQUID",
    "TEMPLATING_SYNTAX_TYPE_NON_LIQUID"
  ],
  "TextChannel": [
    "TEXT_CHANNEL_MMS",
    "TEXT_CHANNEL_SMS"
  ],
  "TextToBuyMessageType": [
    "TEXT_TO_BUY_MESSAGE_TYPE_CAMPAIGN",
    "TEXT_TO_BUY_MESSAGE_TYPE_JOURNEY"
  ],
  "ThreadRunStatus": [
    "THREAD_RUN_STATUS_CANCELLED",
    "THREAD_RUN_STATUS_CANCELLING",
    "THREAD_RUN_STATUS_COMPLETED",
    "THREAD_RUN_STATUS_EXPIRED",
    "THREAD_RUN_STATUS_FAILED",
    "THREAD_RUN_STATUS_IN_PROGRESS",
    "THREAD_RUN_STATUS_QUEUED",
    "THREAD_RUN_STATUS_REQUIRES_ACTION"
  ],
  "TimeComparator": [
    "TIME_COMPARATOR_AFTER",
    "TIME_COMPARATOR_BEFORE",
    "TIME_COMPARATOR_BETWEEN",
    "TIME_COMPARATOR_DAY_OF",
    "TIME_COMPARATOR_MONTH_OF",
    "TIME_COMPARATOR_OVER_ALL_TIME",
    "TIME_COMPARATOR_WITHIN"
  ],
  "TimeDimensionGranularity": [
    "TIME_DIMENSION_GRANULARITY_15MIN",
    "TIME_DIMENSION_GRANULARITY_DAILY",
    "TIME_DIMENSION_GRANULARITY_HOURLY",
    "TIME_DIMENSION_GRANULARITY_MONTHLY",
    "TIME_DIMENSION_GRANULARITY_WEEKLY",
    "TIME_DIMENSION_GRANULARITY_YEARLY"
  ],
  "ToastType": [
    "error",
    "success"
  ],
  "TrafficSplitType": [
    "TRAFFIC_SPLIT_TYPE_CUSTOM",
    "TRAFFIC_SPLIT_TYPE_EVENLY"
  ],
  "UploadManualSegmentOperation": [
    "UPLOAD_MANUAL_SEGMENT_OPERATION_APPEND_TO_EXISTING",
    "UPLOAD_MANUAL_SEGMENT_OPERATION_CREATE_OR_REPLACE"
  ],
  "UserAuthStatus": [
    "authenticated",
    "unauthenticated",
    "unknown"
  ],
  "UserDownloadField": [
    "USER_DOWNLOAD_FIELD_EMAIL"
  ],
  "UserFileDownloadField": [
    "USER_FILE_DOWNLOAD_FIELD_EMAIL",
    "USER_FILE_DOWNLOAD_FIELD_PHONE_NUMBER"
  ],
  "UserFileDownloadStatus": [
    "USER_FILE_DOWNLOAD_STATUS_CANCELLED",
    "USER_FILE_DOWNLOAD_STATUS_COMPLETED",
    "USER_FILE_DOWNLOAD_STATUS_PROCESSING",
    "USER_FILE_DOWNLOAD_STATUS_SUBMITTED"
  ],
  "UserFileDownloadType": [
    "USER_FILE_DOWNLOAD_TYPE_AUTO_SUPPRESSED",
    "USER_FILE_DOWNLOAD_TYPE_SEGMENT",
    "USER_FILE_DOWNLOAD_TYPE_SUPPRESSED",
    "USER_FILE_DOWNLOAD_TYPE_USER_SUPPRESSED"
  ],
  "UserPropertySource": [
    "USER_PROPERTY_SOURCE_ANY",
    "USER_PROPERTY_SOURCE_CUSTOM",
    "USER_PROPERTY_SOURCE_WELL_KNOWN"
  ],
  "UserSearchEvent": [
    "USER_SEARCH_EVENT_ADD_TO_CART",
    "USER_SEARCH_EVENT_CART_UPDATED",
    "USER_SEARCH_EVENT_CHECKOUT_ABANDONED",
    "USER_SEARCH_EVENT_CHECKOUT_STARTED",
    "USER_SEARCH_EVENT_CHECKOUT_UPDATED",
    "USER_SEARCH_EVENT_CUSTOM_EVENT",
    "USER_SEARCH_EVENT_FULFILLMENT_AVAILABLE_FOR_PICKUP",
    "USER_SEARCH_EVENT_FULFILLMENT_CANCELLED",
    "USER_SEARCH_EVENT_FULFILLMENT_CREATED",
    "USER_SEARCH_EVENT_FULFILLMENT_DELIVERED",
    "USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_ATTEMPT_FAILURE",
    "USER_SEARCH_EVENT_FULFILLMENT_DELIVERY_EXCEPTION",
    "USER_SEARCH_EVENT_FULFILLMENT_IN_TRANSIT",
    "USER_SEARCH_EVENT_FULFILLMENT_OUT_FOR_DELIVERY",
    "USER_SEARCH_EVENT_FULFILLMENT_PENDING",
    "USER_SEARCH_EVENT_ORDER_CANCELLED",
    "USER_SEARCH_EVENT_ORDER_CONFIRMED",
    "USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_FAILED",
    "USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CHARGE_SCHEDULED",
    "USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_CREATED",
    "USER_SEARCH_EVENT_ORDER_SUBSCRIPTION_SKIPPED",
    "USER_SEARCH_EVENT_PAGE_VIEW",
    "USER_SEARCH_EVENT_PRODUCT_VIEW",
    "USER_SEARCH_EVENT_PURCHASE",
    "USER_SEARCH_EVENT_REFUND_FAILED",
    "USER_SEARCH_EVENT_REFUND_SUCCEEDED",
    "USER_SEARCH_EVENT_SUBSCRIPTION_SUPPRESSED"
  ],
  "UserStatus": [
    "ANY",
    "AUTO_SUPPRESSED",
    "ELIGIBLE",
    "INELIGIBLE",
    "NOT_YET_JOINED",
    "OPTED_OUT",
    "SUPPRESSED",
    "USER_SUPPRESSED"
  ],
  "ValidationRuleName": [
    "VALIDATION_RULE_UNSUBSCRIBE_LINK"
  ],
  "Vendor": [
    "AFTERSHIP",
    "BLUECORE",
    "BLUESHIFT_EVENTS",
    "BRAZE",
    "BRONTO",
    "CHEETAHMAIL",
    "CHEETAHMAIL_SETUSER",
    "CONVERSIO",
    "CORDIAL",
    "CROWD_TWIST",
    "CUSTOM",
    "DRIP",
    "EMARSYS",
    "FRESHDESK",
    "GORGIAS",
    "HUBSPOT",
    "IBM_WATSON",
    "ITERABLE",
    "JUSTUNO",
    "KLAVIYO",
    "KLAVIYO_SUBSCRIBE",
    "KUSTOMER",
    "LISTRAK",
    "MAILCHIMP",
    "MAROPOST",
    "MAURICES_RESPONSYS",
    "MPARTICLE",
    "OMAHA_STEAKS",
    "OMNISEND",
    "RAINBOWSHOPS",
    "REEBOK",
    "REJOINER",
    "RESPONSYS",
    "RETENTION_SCIENCE",
    "REVOLVE",
    "SAILTHRU",
    "SALESFORCE",
    "SALESFORCE_DATA_EXTENSION",
    "SALESFORCE_JOURNEY",
    "SEGMENT_ANALYTICS",
    "SEGMENT_ANALYTICS_PG_BRANDS",
    "SHOPIFY",
    "SILVERPOP",
    "SIMON",
    "SPROUTS",
    "SWELL",
    "VENDOR_ACTIVECAMPAIGN",
    "VENDOR_ADOBE",
    "VENDOR_ADOBE_EXPERIENCE_PLATFORM",
    "VENDOR_AIRSHIP",
    "VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE",
    "VENDOR_AWEBER",
    "VENDOR_BAZAARVOICE",
    "VENDOR_BIGCOMMERCE",
    "VENDOR_BLOOMREACH",
    "VENDOR_BLOTOUT_WHITELABEL",
    "VENDOR_BRIERLY",
    "VENDOR_BUBBLEPAY_SHOPIFY",
    "VENDOR_CAMPAIGN_MONITOR",
    "VENDOR_CHATTER",
    "VENDOR_CONSTANT_CONTACT",
    "VENDOR_CONVERTKIT",
    "VENDOR_DOTDIGITAL",
    "VENDOR_ELOQUA",
    "VENDOR_EMAIL_OPT_OUT",
    "VENDOR_EPSILON",
    "VENDOR_EPSILON_LOYALTY",
    "VENDOR_EXPONEA",
    "VENDOR_FACEBOOK_ADS",
    "VENDOR_FIVETRAN",
    "VENDOR_GLADLY",
    "VENDOR_GOOGLE_ADS",
    "VENDOR_GOOGLE_CONTENT_API",
    "VENDOR_GOOGLE_TAG_MANAGER",
    "VENDOR_HELPSCOUT",
    "VENDOR_INTERCOM",
    "VENDOR_LIVEPERSON",
    "VENDOR_LOAD_TEST",
    "VENDOR_LOYALTYLION",
    "VENDOR_MAGENTO",
    "VENDOR_MAILTRAIN",
    "VENDOR_MINDBODY",
    "VENDOR_NARVAR",
    "VENDOR_OLO",
    "VENDOR_OMETRIA",
    "VENDOR_OPTIMIZELY",
    "VENDOR_OPTIMOVE",
    "VENDOR_PASSKIT",
    "VENDOR_PAYTRONIX",
    "VENDOR_POST_PILOT",
    "VENDOR_PUNCHH",
    "VENDOR_REAMAZE",
    "VENDOR_RECHARGE",
    "VENDOR_REMARKETY",
    "VENDOR_RICHPANEL",
    "VENDOR_SALESFORCE_SERVICE_CLOUD",
    "VENDOR_SATISFILABS",
    "VENDOR_SEGMENT",
    "VENDOR_SENDGRID",
    "VENDOR_SENDINBLUE",
    "VENDOR_SENDLANE",
    "VENDOR_SKECHERS",
    "VENDOR_SMILE_IO",
    "VENDOR_STAMPED",
    "VENDOR_TALLY",
    "VENDOR_TALON_ONE",
    "VENDOR_TRAVELCLICK",
    "VENDOR_TREASUREDATA",
    "VENDOR_WOOCOMMERCE",
    "VENDOR_WORLD_MARKET",
    "VENDOR_YESMAIL",
    "VENDOR_YOTPO",
    "VENDOR_ZETA_GLOBAL",
    "VENDOR_ZOHO",
    "WHATCOUNTS",
    "ZAIUS",
    "ZENDESK"
  ],
  "VisibilityType": [
    "ALL",
    "NONE",
    "SUPERUSER"
  ],
  "WarmupCampaignStatus": [
    "WARMUP_CAMPAIGN_STATUS_SCHEDULED",
    "WARMUP_CAMPAIGN_STATUS_UNSET"
  ],
  "WarmupWarnings": [
    "WARMUP_WARNING_LOW_90_DAYS_ENGAGEMENT",
    "WARMUP_WARNING_LOW_365_DAYS_ENGAGEMENT",
    "WARMUP_WARNING_UNSET"
  ],
  "WebhookStatus": [
    "DISABLED",
    "ENABLED",
    "INACTIVE"
  ],
  "WellKnownPropertyType": [
    "WELL_KNOWN_PROPERTY_AREA_CODE",
    "WELL_KNOWN_PROPERTY_CITY",
    "WELL_KNOWN_PROPERTY_CONVERSATIONAL_STATE",
    "WELL_KNOWN_PROPERTY_COUNTRY",
    "WELL_KNOWN_PROPERTY_FIRST_NAME",
    "WELL_KNOWN_PROPERTY_FULL_NAME",
    "WELL_KNOWN_PROPERTY_IS_LITIGIOUS",
    "WELL_KNOWN_PROPERTY_LANGUAGE",
    "WELL_KNOWN_PROPERTY_LAST_NAME",
    "WELL_KNOWN_PROPERTY_LOCALE",
    "WELL_KNOWN_PROPERTY_MAIL_EXCHANGE",
    "WELL_KNOWN_PROPERTY_REGION",
    "WELL_KNOWN_PROPERTY_TIMEZONE",
    "WELL_KNOWN_PROPERTY_TYPES_BESTSELLER_RECOMMENDATION",
    "WELL_KNOWN_PROPERTY_TYPES_CATEGORY_BESTSELLER_LAST_PURCHASED",
    "WELL_KNOWN_PROPERTY_TYPES_ESTIMATED_LIFETIME_VALUE",
    "WELL_KNOWN_PROPERTY_TYPES_IS_PART_OF_AI_JOURNEY",
    "WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN",
    "WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING",
    "WELL_KNOWN_PROPERTY_TYPES_NOTIFY_WHEN_BACK_IN_STOCK",
    "WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID",
    "WELL_KNOWN_PROPERTY_TYPES_SMS_CHURN_PROBABILITY",
    "WELL_KNOWN_PROPERTY_ZIP"
  ],
  "ZendeskJwtType": [
    "ZENDESK_JWT_TYPE_MESSAGING",
    "ZENDESK_JWT_TYPE_SSO"
  ]
};